import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import LoadingButton from './LoadingButton';
import StepIndicator from './StepIndicator';
import LoadingIndicator from './LoadingIndicator';
import ReviewLessons from './ReviewLessons';
import ReviewTopics from './ReviewTopics';
import { streamStudyPlan, streamStudyPlanTopics, streamPreviousKnowledge, streamNeeds, streamGoals, getEmojis } from '../api/lfApiStudyPlan';

function getStudyPlan(plan, lessons) {
    return {
        subject: plan.subject,
        goal: plan.goal,
        previous_knowledge: plan.previous_knowledge,
        needs: plan.needs,
        emoji: plan.emoji,
        num_lessons: parseInt(plan.num_lessons),
        lessons: lessons,
    }
};

const AddStudyPlan = ({ username, onOk }) => {
    const [plan, setPlan] = useState({
        'subject': '',
        'goal': '',
        'previous_knowledge': '',
        'needs': '',
        'num_lessons': '4',
        'emoji': '',
        'lessons': [],
    });
    const [lessons1, setLessons1] = useState([]);
    const [lessons2, setLessons2] = useState([]);
    const [lessonsWithTopics1, setLessonsWithTopics1] = useState([]);
    const [lessonsWithTopics2, setLessonsWithTopics2] = useState([]);

    const maxNonse = 5;
    const [planProp1, setPlanProp1] = useState({ nonse: 0, num_lessons: plan.num_lessons });
    const [planProp2, setPlanProp2] = useState({ nonse: 0, num_lessons: plan.num_lessons });

    const subjects = [
        "Math", "Science", "English", "History", "Social studies",
        "Geography", "Art", "Environmental studies", "Physics", "Chemistry",
        "Robotics", "Coding", "Chess", "Economics", "Financial literacy", "Personal development",
        "Creative writing", "Photography"
    ];

    const [goals, setGoals] = useState([]);
    const [isGoalsModified, setIsGoalsModified] = useState(false);

    const [previousKnowledges, setPreviousKnowledges] = useState([]);
    const [isPreviousKnowledgeModified, setIsPreviousKnowledgeModified] = useState(false);

    const [needs, setNeeds] = useState([]);
    const [isNeedsModified, setIsNeedsModified] = useState(false);

    const [emojis, setEmojis] = useState([]);

    const fetchGoals = async () => {
        setGoals([]);
        setIsGoalsModified(false);
        await streamGoals(plan, username, setGoals);
    };

    const fetchPreviousKnowledge = async () => {
        setPreviousKnowledges([]);
        setIsPreviousKnowledgeModified(false);
        await streamPreviousKnowledge(plan, username, setPreviousKnowledges);
    };  

    const fetchNeeds = async () => {
        setNeeds([]);
        setIsNeedsModified(false);
        await streamNeeds(plan, username, setNeeds);
    };
    
    const navigate = useNavigate();

    const navigateToRoot = () => {
        navigate('');
    };

    const navigateToGoal = async () => {
        navigate('goal');
        await fetchGoals();
    };

    const navigateToPreviousKnowledge = async () => {
        navigate('previous_knowledge');
        await fetchPreviousKnowledge();
    };

    const navigateToNeeds = async () => {
        navigate('needs');
        await fetchNeeds();
    };

    const navigateToEmojis = async () => {
        navigate('emojis');

        setEmojis([]);
        await getEmojis(plan, username, setEmojis);
    };

    const navigateToPreview = async () => {
        navigate('preview');
        
        // Only fetch new suggestions if we don't have any lessons yet
        if (lessons1.length === 0 || lessons2.length === 0) {
            await fetchSuggestedStudyPlans(plan);
        }
    };

    const navigateToTopics = async (lessons, planProp) => {
        setPlan({ ...plan, num_lessons: planProp.num_lessons, lessons: lessons });

        navigate('topics');
        await fetchSuggestedTopics(lessons);
    };

    const _incrementNonse = (planProp, setPlanProp, maxNonse) => {
        const n = (planProp.nonse + 1) % maxNonse;
        const newState = { ...planProp, nonse: n };
        setPlanProp(newState);
        return newState;
    };

    const incrementNonse1 = () => {
        return _incrementNonse(planProp1, setPlanProp1, maxNonse);
    };

    const incrementNonse2 = () => {
        return _incrementNonse(planProp2, setPlanProp2, maxNonse);
    };

    const renderStep = (stepNum) => {
        return <div>
            <StepIndicator 
                currentStep={stepNum} numSteps={6} 
                onClicks={[
                    navigateToRoot, navigateToGoal, navigateToPreviousKnowledge,
                    navigateToEmojis, navigateToPreview
                ]}/>
        </div>
    };
    
    const fetchSuggestedStudyPlan1 = async (plan, planProp) => {
        await streamStudyPlan(plan, username, planProp, 'default', setLessons1);
    };

    const fetchSuggestedStudyPlan2 = async (plan, planProp) => {
        await streamStudyPlan(plan, username, planProp, 'secondary', setLessons2);
    };

    const fetchSuggestedStudyPlans = async (plan) => {
        await Promise.all([
            fetchSuggestedStudyPlan1(plan, planProp1),
            fetchSuggestedStudyPlan2(plan, planProp2),
        ]);
    };

    const fetchSuggestedTopic1 = async (lessons, planProp) => {
        await streamStudyPlanTopics(plan, username, lessons, planProp, 'default', setLessonsWithTopics1);
    };

    const fetchSuggestedTopic2 = async (lessons, planProp) => {
        await streamStudyPlanTopics(plan, username, lessons, planProp, 'secondary', setLessonsWithTopics2);
    };

    const fetchSuggestedTopics = async (lessons) => {
        await Promise.all([
            fetchSuggestedTopic1(lessons, planProp1.nonse),
            fetchSuggestedTopic2(lessons, planProp2.nonse),
        ]);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPlan(prevState => ({ ...prevState, [name]: value }));

        if (name === 'goal') {
            setIsGoalsModified(true);
        } else if (name === 'previous_knowledge') {
            setIsPreviousKnowledgeModified(true);
        } else if (name === 'needs') {
            setIsNeedsModified(true);
        }
    };

    return <div className="text-left mx-auto max-w-screen-lg">
        <h1 className="text-xl">Add study plan</h1>

        <div className="mb-8">
            <div>
                A study plan is a personalized learning plan designed to guide your child through their learning goals.
            </div>
        </div>

        <Routes>
            <Route path="" element={<div>
                { renderStep(1) }
                <div className="text-lg">
                    Enter the subject:
                </div>
                <input
                    type="text"
                    className="mt-2 w-full md:w-2/3 border border-gray-300 p-1"
                    name="subject"
                    value={plan.subject}
                    maxLength={30}
                    onChange={handleChange} />

                <div className="my-6 md:w-2/3">
                    {
                        subjects.map((subject, index) => (
                            <button key={index}
                                className={`${plan.subject === subject ? "text-blue-500 border-blue-500" : "text-gray-500 border-gray-500"} bg-white text-sm border rounded-lg p-1 mr-1 mb-2`}
                                onClick={() => setPlan({ ...plan, subject: subject })}>
                                {subject}
                            </button>
                        ))
                    }
                </div>

                <button
                    className={`${plan.subject.length === 0 ? "bg-gray-400" : "bg-blue-500"} text-white py-2 px-4 rounded`}
                    disabled={plan.subject.length === 0}
                    onClick={navigateToGoal}>
                    Next
                </button>
            </div>} />
            <Route path="goal" element={<div>
                { renderStep(2) }
                <div className="text-lg">
                    Enter learning goals:
                </div>
                <textarea
                    className="mt-2 w-full border border-gray-300 p-1 h-[5rem] sm:h-[4rem]"
                    name="goal"
                    value={plan.goal}
                    maxLength={120}
                    onChange={handleChange} />

                <div className="my-2">
                    {
                        goals.length === 0 && <LoadingIndicator />
                    }
                    {isGoalsModified && 
                        <div className="my-2">
                            <LoadingButton 
                                imgSrc="/icon-refresh.png" 
                                text="Generate new suggestions"
                                onClick={async () => await fetchGoals()} />
                        </div>
                    }
                    {
                        goals.map((goal, index) => (
                            <button key={index}
                                className={`${plan.goal === goal ? "text-blue-500 border-blue-500" : "text-gray-500 border-gray-500"} bg-white text-sm text-left border rounded-lg p-1 mr-1 mb-2`}
                                onClick={() => setPlan({ ...plan, goal: goal })}>
                                {goal}
                            </button>
                        ))
                    }
                </div>

                <button
                    className={`${plan.goal.length === 0 ? "bg-gray-400" : "bg-blue-500"} text-white py-2 px-4 rounded`}
                    disabled={plan.goal.length === 0}
                    onClick={navigateToPreviousKnowledge}>
                    Next
                </button>
            </div>} />
            <Route path="previous_knowledge" element={<div>
                { renderStep(3) }
                <div className="text-lg">
                    Enter previous knowledge:
                </div>
                <textarea
                    className="mt-2 w-full border border-gray-300 p-1 h-[5rem] sm:h-[4rem]"
                    name="previous_knowledge"
                    value={plan.previous_knowledge}
                    maxLength={120}
                    onChange={handleChange} />

                <div className="my-6">
                    {
                        previousKnowledges.length === 0 && <LoadingIndicator />
                    }
                    {
                        previousKnowledges.map((previousKnowledge, index) => (
                            <button key={index}
                                className={`${plan.previous_knowledge === previousKnowledge ? "text-blue-500 border-blue-500" : "text-gray-500 border-gray-500"} bg-white text-sm text-left border rounded-lg p-1 mr-1 mb-2`}
                                onClick={() => setPlan({ ...plan, previous_knowledge: previousKnowledge })}>
                                {previousKnowledge}
                            </button>
                        ))
                    }
                </div>

                <button
                    className={`${plan.previous_knowledge.length === 0 ? "bg-gray-400" : "bg-blue-500"} text-white py-2 px-4 rounded`}
                    disabled={plan.previous_knowledge.length === 0}
                    onClick={navigateToEmojis}>
                    Next
                </button>
            </div>} />
            <Route path="needs" element={<div>
                { renderStep(4) }
                <div className="text-lg">
                    Enter educational needs (optional):
                </div>
                <textarea
                    className="mt-2 w-full border border-gray-300 p-1 h-[5rem] sm:h-[4rem]"
                    name="needs"
                    placeholder="eg, Requires extra help with grammar and sentence structure, etc"
                    value={plan.needs}
                    maxLength={120}
                    onChange={handleChange} />

                <div className="my-6">
                    {
                        needs.length === 0 && <LoadingIndicator />
                    }
                    {
                        needs.map((needs, index) => (
                            <button key={index}
                                className={`${plan.needs === needs ? "text-blue-500 border-blue-500" : "text-gray-500 border-gray-500"} bg-white text-sm text-left border rounded-lg p-1 mr-1 mb-2`}
                                onClick={() => setPlan({ ...plan, needs: needs })}>
                                {needs}
                            </button>
                        ))
                    }
                </div>

                <button className="bg-blue-500 text-white py-2 px-4 rounded"
                    onClick={navigateToEmojis}>
                    Next
                </button>
            </div>} />
            <Route path="emojis" element={<div>
                { renderStep(4) }
                <div className="text-lg">
                    Enter study plan emoji:
                </div>
                <input 
                    type="text"
                    className="mt-2 w-30 border border-gray-300 p-1"
                    name="emoji"
                    maxLength={2}
                    value={plan.emoji}
                    onChange={handleChange} />

                <div className="my-6">
                    {
                        emojis.length === 0 && <LoadingIndicator />
                    }
                    {
                        emojis.map((emoji, index) => (
                            <button key={index}
                                className={`${plan.emoji === emoji ? "text-blue-500 border-blue-500" : "text-gray-500 border-gray-500"} bg-white text-sm border rounded-lg p-1 mr-2 mb-2`}
                                onClick={() => setPlan({ ...plan, emoji: emoji })}>
                                {emoji}
                            </button>
                        ))
                    }
                </div>

                <button 
                    className={`${plan.emoji.length === 0 ? "bg-gray-400" : "bg-blue-500"} text-white py-2 px-4 rounded`}
                    disabled={plan.emoji.length === 0}
                    onClick={navigateToPreview}>
                    Preview study plan
                </button>
            </div>} />
            <Route path="preview" element={<div>
                { renderStep(5) }
                <div className="text-lg">
                    Review study plan
                </div>
                <div>
                    Here are the suggested study plans. You can accept one of the suggestions or reorder lessons via drag and drop.
                    You can also go back to edit the study plan.
                </div>

                <ReviewLessons 
                    lessons1={lessons1}
                    lessons2={lessons2}
                    planProp1={planProp1}
                    planProp2={planProp2}
                    plan={plan}
                    username={username}
                    setLessons1={setLessons1}
                    setLessons2={setLessons2}
                    setPlanProp1={setPlanProp1}
                    setPlanProp2={setPlanProp2}
                >
                    {
                        lessons2.length > 0 
                            ? <div className="md:hidden">
                                <button className="bg-blue-500 text-white py-2 px-4 rounded"
                                    onClick={() => navigateToTopics(lessons1, planProp1)}>
                                    Accept suggestion 1
                                </button>
                            </div>
                            : <></>
                    }
                    <div className="md:hidden">
                        <button className="bg-blue-500 text-white py-2 px-4 rounded"
                            onClick={() => navigateToTopics(lessons2, planProp2)}>
                            Accept suggestion 2
                        </button>
                    </div>
                </ReviewLessons>

                {
                    lessons2.length > 0 && <div className="hidden md:flex justify-center gap-2 mt-4">
                        <button className="bg-blue-500 text-white py-2 px-4 rounded ml-12"
                            onClick={() => navigateToTopics(lessons1, planProp1)}>
                            Accept suggestion 1
                        </button>
                        <button className="bg-blue-500 text-white py-2 px-4 rounded"
                            onClick={() => navigateToTopics(lessons2, planProp2)}>
                            Accept suggestion 2
                        </button>
                    </div>
                }
            </div>} />

            <Route path="topics" element={<div>
                { renderStep(6) }
                <div className="text-lg">
                    Review topics
                </div>
                <div>
                    Here are the suggested study plans with topics. You can accept one of the suggestions or reorder topics via drag and drop.
                    You can also go back to edit the study plan.
                </div>

                <ReviewTopics
                    lessonsWithTopics1={lessonsWithTopics1}
                    lessonsWithTopics2={lessonsWithTopics2}
                    plan={plan}
                    username={username}
                    setLessonsWithTopics1={setLessonsWithTopics1}
                    setLessonsWithTopics2={setLessonsWithTopics2}
                >
                {
                    lessonsWithTopics2.length > 0 ? 
                        <div className="md:hidden mt-4">
                            <button className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
                                onClick={() => onOk(getStudyPlan(plan, lessonsWithTopics1))}>
                                Accept suggestion 1
                            </button>
                        </div>
                        : <></>
                }
                <div className="md:hidden">
                    <button className="bg-blue-500 text-white py-2 px-4 rounded"
                        onClick={() => onOk(getStudyPlan(plan, lessonsWithTopics2))}>
                        Accept suggestion 2
                    </button>
                </div>
            </ReviewTopics>
                
                {
                    lessons2.length > 0 && <div className="hidden md:flex justify-center gap-2 mt-4">
                        <button className="bg-blue-500 text-white py-2 px-4 rounded ml-12"
                            onClick={() => onOk(getStudyPlan(plan, lessonsWithTopics1))}>
                            Accept suggestion 1
                        </button>
                        <button className="bg-blue-500 text-white py-2 px-4 rounded"
                            onClick={() => onOk(getStudyPlan(plan, lessonsWithTopics2))}>
                            Accept suggestion 2
                        </button>
                    </div>
                }
            </div>} />
        </Routes>
    </div>
};

export default AddStudyPlan;
