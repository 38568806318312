import React from 'react';
import './StudyPlanTopic.css';

const StudyPlanTopic = ({ topic, index, topicType = 'current' }) => {
    return <div className="list-counter-item p-1">
        <div className={`circle-number ${
            topicType === 'past' 
                ? "circle-number-done" 
                : (topicType === 'current' ? "circle-number-in-progress" : "")}`}>
            {index + 1}
        </div>
        <div className="item-text">{topic.title}</div>
    </div>;
};

export default StudyPlanTopic;
