import React, { useState, useEffect } from 'react';
import DialogBox from './DialogBox';

const EditTopic = ({ topic, isOpen, onClose, onSave }) => {
    const [editedTitle, setEditedTitle] = useState(topic?.title || '');
    
    useEffect(() => {
        if (topic) {
            setEditedTitle(topic.title || '');
        }
    }, [topic]);
    
    const handleSave = () => {
        onSave({
            ...topic,
            title: editedTitle
        });
    };
    
    const handleCancel = () => {
        onClose();
    };
    
    return (
        <DialogBox
            title="Edit Topic"
            buttonText="Save"
            isLoading={false}
            isDialogOpen={isOpen}
            setIsDialogOpen={() => onClose()}
            onOk={handleSave}
            onCancel={handleCancel}
        >
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    Topic Title
                </label>
                <input
                    type="text"
                    className="w-full border border-gray-300 p-2 rounded"
                    value={editedTitle}
                    onChange={(e) => setEditedTitle(e.target.value)}
                />
            </div>
        </DialogBox>
    );
};

export default EditTopic; 