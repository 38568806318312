import LoadingButton from './LoadingButton';

const DialogBox = ({ title, buttonText, isLoading, isDialogOpen, setIsDialogOpen, onOk, onCancel, children }) => {
    const handleCancel = () => {
        onCancel();
        setIsDialogOpen(false);
    };

    return isDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-[32rem]">
                <h2 className="text-lg mb-4">{title}</h2>

                {children}

                <div className="flex justify-end gap-2 mt-4">
                    <LoadingButton 
                        text={buttonText}
                        onClick={onOk}
                        isLoading={isLoading}
                    />
                    <button
                        className="px-4 py-2 bg-blue-500 rounded text-white"
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DialogBox;
