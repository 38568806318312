import React, { useState, useEffect } from 'react';
import DialogBox from './DialogBox';

const EditLesson = ({ lesson, isOpen, onClose, onSave }) => {
    const [editedTitle, setEditedTitle] = useState(lesson?.title || '');
    const [editedExplanation, setEditedExplanation] = useState(lesson?.explanation || '');
    
    useEffect(() => {
        if (lesson) {
            setEditedTitle(lesson.title || '');
            setEditedExplanation(lesson.explanation || '');
        }
    }, [lesson]);
    
    const handleSave = () => {
        onSave({
            ...lesson,
            title: editedTitle,
            explanation: editedExplanation
        });
    };
    
    const handleCancel = () => {
        onClose();
    };
    
    return (
        <DialogBox
            title="Edit Lesson"
            buttonText="Save"
            isLoading={false}
            isDialogOpen={isOpen}
            setIsDialogOpen={() => onClose()}
            onOk={handleSave}
            onCancel={handleCancel}
        >
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    Lesson Title
                </label>
                <input
                    type="text"
                    className="w-full border border-gray-300 p-2 rounded"
                    value={editedTitle}
                    onChange={(e) => setEditedTitle(e.target.value)}
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    Lesson Description
                </label>
                <textarea
                    className="w-full border border-gray-300 p-2 rounded h-24"
                    value={editedExplanation}
                    onChange={(e) => setEditedExplanation(e.target.value)}
                />
            </div>
        </DialogBox>
    );
};

export default EditLesson; 