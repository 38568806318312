import { api_get, api_post, api_delete } from './lfApi';
import { stream_post } from './lfApiFetch';
import { generateUniqueId } from './uniqueId';
import { extendArray } from './array';
import { processText, parseLines } from './stream';

async function processResponse(res, setResponse) {
    await processText(res, (text) => setResponse(parseLines(text)));
};

function _parseLesson(lesson) {
    const separator = lesson.indexOf(':');
    const id = generateUniqueId('lesson_');
    if (separator === -1) {
        return {
            id: id,
            title: lesson,
            explanation: '',
        }
    }
    else {
        return {
            id: id,
            title: lesson.substring(0, separator),
            explanation: lesson.substring(separator + 1).trim(),
        }
    }
}

function parseLessons(lessons) {
    return parseLines(lessons).map(_parseLesson);
}

function _parseTopic(topic) {
    const separator = topic.indexOf(':');
    const id = generateUniqueId('topic_');
    if (separator === -1) {
        return null;
    }
    return {
        lesson_index: parseInt(topic.substring(0, separator)) - 1,
        id: id,
        title: topic.substring(separator + 1).trim(),
    }
}

function parseTopics(lessons, topics) {
    const topics_list = extendArray(
        parseLines(topics)
            .map(_parseTopic)
            .filter((topic) => topic !== null)
            .reduce((acc, topic) => {
                acc = extendArray(acc, topic.lesson_index + 1, []);
                acc[topic.lesson_index].push({
                    id: topic.id,
                    title: topic.title,
                });
                return acc;
            }, []),
        lessons.length,
        []);

    return lessons.map((lesson, index) => {
        return {
            ...lesson,
            topics: topics_list[index],
        };
    });
}

function parseLesson(lessons, lesson) {
    const lines = parseLines(lesson);
    if (lines.length === 0) return lessons;

    const parsedLesson = _parseLesson(lines[0]);

    // Find existing lesson with same title
    const existingLessonIndex = lessons.findIndex(existingLesson =>
        existingLesson.title === parsedLesson.title
    );

    const topics = lines.slice(1)
        .map((line, index) => ({
            ...line,
            // Prepend "1:" to each line so _parseTopic knows this is for the first lesson
            text: `1:${line}`
        }))
        .map(line => _parseTopic(line.text))
        .filter(topic => topic !== null)
        .map(topic => ({
            id: topic.id,
            title: topic.title
        }));

    if (existingLessonIndex >= 0) {
        // Update topics of existing lesson
        const updatedLessons = [...lessons];
        updatedLessons[existingLessonIndex].topics = topics;
        return updatedLessons;
    } else {
        // Add new lesson with topics
        parsedLesson.topics = topics;
        return [parsedLesson, ...lessons];
    }
}

export async function streamPreviousKnowledge(plan, username, setPreviousKnowledges) {
    try {
        const res = await stream_post('lesson_plan/previous_knowledge', {
            child_username: username,
            subject: plan.subject,
            goal: plan.goal,
        });
        await processResponse(res, setPreviousKnowledges)
    }
    catch (error) {
        console.error('Error fetching study plan previous knowledge: ', error);
        if (!error.response || error.response.status === 401) {
            setPreviousKnowledges([]);
        }
    }
}

export async function streamNeeds(plan, username, setNeeds) {
    try {
        const res = await stream_post('lesson_plan/needs', {
            child_username: username,
            subject: plan.subject,
            goal: plan.goal,
            previous_knowledge: plan.previous_knowledge,
        });
        await processResponse(res, setNeeds)
    }
    catch (error) {
        console.error('Error fetching study plan needs: ', error);
        if (!error.response || error.response.status === 401) {
            setNeeds([]);
        }
    }
}

export async function streamGoals(plan, username, setGoals) {
    try {
        const res = await stream_post('lesson_plan/goals', {
            child_username: username,
            subject: plan.subject,
            description: plan.goal,
        });
        await processResponse(res, setGoals)
    }
    catch (error) {
        console.error('Error fetching study plan goals: ', error);
        if (!error.response || error.response.status === 401) {
            setGoals([]);
        }
    }
}

export async function getEmojis(plan, username, setEmojis, setError) {
    try {
        const res = await api_post('lesson_plan/emojis', {
            child_username: username,
            subject: plan.subject,
            goal: plan.goal,
        }, setError);
        setEmojis(res.data.emojis);
    }
    catch (error) {
        console.error('Error fetching study plan emojis: ', error);
    }
}

export async function streamStudyPlan(plan, username, planProp, llm_name, setLessons) {
    const params = {
        ...plan,
        num_lessons: planProp.num_lessons,
        child_username: username,
        nonse: planProp.nonse,
        llm_name,
    };
    const res = await stream_post('lesson_plan/stream', params);
    const fullText = await processText(res, (text) => setLessons(parseLessons(text)));
    return parseLessons(fullText);
};

export async function streamStudyPlanTopics(plan, username, lessons, planProp, llm_name, setLessons) {
    const params = {
        child_username: username,
        subject: plan.subject,
        goal: plan.goal,
        lessons,
        nonse: planProp.nonse,
        llm_name,
    };
    const res = await stream_post('lesson_plan/topics', params);
    await processText(res, (text) => setLessons(parseTopics(lessons, text)));
};

export async function streamTopics(plan, username, lessons, setLessons, llm_name = '') {
    const params = {
        child_username: username,
        subject: plan.subject,
        goal: plan.goal,
        lessons: lessons,
    };
    const futures = [
        stream_post('lesson_plan/topics', { ...params, ...{ llm_name: llm_name } }),
    ];

    const onResponse = (lessons, setLessons) => {
        return async (res) => {
            await processText(res, (text) => setLessons(parseTopics(lessons, text)));
        };
    };
    const f1 = futures[0].then(onResponse(lessons, setLessons));
    await f1;
}

export async function streamLesson(plan, lesson_description, username, lessons, setLessons, llm_name = '') {
    const params = {
        child_username: username,
        subject: plan.subject,
        goal: plan.goal,
        previous_knowledge: plan.previous_knowledge,
        needs: plan.needs,
        lesson_description: lesson_description,
    };
    const futures = [
        stream_post('lesson_plan/lesson', { ...params, ...{ llm_name: llm_name } }),
    ];

    const onResponse = (lessons, setLessons) => {
        return async (res) => {
            await processText(res, (text) => setLessons(parseLesson(lessons, text)));
        };
    };
    const f1 = futures[0].then(onResponse(lessons, setLessons));
    await f1;
}

export async function updateCurrentLessonIndex(username, studyPlanId, lessonIndex, topicIndex, setError) {
    try {
        await api_post(`/lesson_plan/lesson_index`, {
            child_username: username,
            study_plan_id: studyPlanId,
            lesson_index: lessonIndex,
            topic_index: topicIndex,
        }, setError);
    }
    catch (error) {
        console.error('Error updating current lesson index: ', error);
    }
}

export async function getStudyPlans(username, isArchived, setError) {
    try {
        const response = await api_get('/lesson_plan/', {
            child_username: username,
            is_deleted: isArchived,
        }, setError);
        if (response.status === 200) {
            return response.data;
        }
        else {
            console.error('Failed to fetch study plans');
        }
    }
    catch (error) {
        console.error('Error fetching study plans: ', error);
    }
    return null;
}

export async function postStudyPlan(username, studyPlan, setError) {
    try {
        const response = await api_post('/lesson_plan/', {
            child_username: username,
            study_plan: studyPlan,
        }, setError);
        if (response.status === 200) {
            return true;
        }
        else {
            console.error('Failed to upsert study plan: ', studyPlan);
        }
    }
    catch (error) {
        console.error('Error upserting study plan:', error);
    }
    return false;
}

export async function postStudyPlanDisplayOrder(username, studyPlans, setError) {
    try {
        const response = await api_post('/lesson_plan/display_orders', {
            child_username: username,
            study_plan_ids: studyPlans.map(plan => plan.id),
        }, setError);
        if (response.status === 200) {
            return true;
        }
        else {
            console.error('Failed to upsert study plan display order: ', studyPlans);
        }
    }
    catch (error) {
        console.error('Error upserting study plan display order:', error);
    }
    return false;
}

export async function delStudyPlan(username, studyPlan, setError) {
    try {
        const response = await api_delete('/lesson_plan/', {
            child_username: username,
            study_plan_id: studyPlan.id,
        }, setError);
        if (response.status === 200) {
            return true;
        }
        else {
            console.error('Failed to delete study plan: ', studyPlan);
        }
    }
    catch (error) {
        console.error('Error deleting study plan:', error);
    }
    return false;
}
