import React, { useState, useRef, useEffect } from 'react';
import { stream_post } from '../api/lfApiFetch';
import { processText } from '../api/stream';
import AnimatingDot from './AnimatingDot';
import LatexRenderer from './LatexRenderer';
import Speech from './Speech';
import FeedbackButton from './FeedbackButton';

const _sendChatMessage = async (chatId, message) => {
  try {
    return await stream_post('/chat/question', {
      chat_id: chatId,
      message: message
    });
  } catch (error) {
    console.error('Error sending chat message:', error);
    return '';
  }
};

const _streamChatSpeech = async (chatId) => {
  try {
    return await stream_post('/chat/speech', {
      chat_id: chatId
    });
  } catch (error) {
    console.error('Error streaming chat speech:', error);
    return '';
  }
};

const VideoChat = ({ question }) => {
  const [chatHistory, setChatHistory] = useState(question.messages.map((message) => ({ sender: message.role, text: message.message })));
  const [userMessage, setUserMessage] = useState('');
  const [isAssistantLoading, setIsAssistantLoading] = useState(false);
  const [placeholderText, setPlaceholderText] = useState('Ask a question about the video...');
  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  // Scroll to bottom when chat history changes
  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  // Scroll to bottom when component mounts
  useEffect(() => {
    scrollToBottom();
  }, []);

  async function handleSendMessage(event) {
    event.preventDefault();
    
    if (!userMessage.trim() || isAssistantLoading) return;
    
    const message = userMessage;
    setUserMessage('');
    setPlaceholderText('');
    setIsAssistantLoading(true);
    
    // Add user message to chat history
    const userMessageObj = { id: Date.now(), sender: 'user', text: message };
    setChatHistory(prev => [...prev, userMessageObj]);
    
    try {
      // Add assistant message placeholder
      const assistantMessageId = Date.now() + 1;
      setChatHistory(prev => [...prev, { id: assistantMessageId, sender: 'assistant', text: '' }]);
      
      // Stream the response
      const res = await _sendChatMessage(question.chat_id, message);
      let fullText = '';
      
      await processText(res, (text) => {
        fullText = text;
        setChatHistory(prev => 
          prev.map(msg => 
            msg.id === assistantMessageId 
              ? { ...msg, text } 
              : msg
          )
        );
      });
      
      // Stream speech if available
      if (fullText) {
        const speechRes = await _streamChatSpeech(question.chat_id);
        
        // Update the message with speech data
        setChatHistory(prev => 
          prev.map(msg => 
            msg.id === assistantMessageId 
              ? { ...msg, speechData: speechRes } 
              : msg
          )
        );
      }
    } catch (error) {
      console.error('Error in chat:', error);
    } finally {
      setIsAssistantLoading(false);
      setPlaceholderText('Ask a question about the video...');
    }
  }

  function handleMessageChange(event) {
    if (event.target.value.indexOf("\n") >= 0 && event.target.value.trim() !== '') {
      event.preventDefault();
      setUserMessage(event.target.value.replace(/\n/g, ''));
      handleSendMessage({ preventDefault: () => {} });
    } else {
      setUserMessage(event.target.value);
    }
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage(event);
    }
  }

  return (
    <div className="flex flex-col h-full">
      <div ref={chatContainerRef} className="flex-grow overflow-y-auto mb-4 max-h-[400px]">
        {chatHistory.map((message, index) => (
          <div key={index} className="mb-3">
            {message.sender === 'assistant' && (
              <div className="flex w-full">
                <div className="w-full">
                  <div className="inline-block p-2 border border-gray-300 rounded-xl bg-white">
                    <LatexRenderer text={message.text} />
                    {index === chatHistory.length - 1 && isAssistantLoading && (
                      <span className="ml-1">
                        <AnimatingDot />
                      </span>
                    )}
                    {index === chatHistory.length - 1 && 
                     !isAssistantLoading && (
                      <Speech 
                        getSpeech={async () => _streamChatSpeech(question.chat_id)} 
                      />
                    )}
                  </div>
                </div>
                {index === chatHistory.length - 1 && 
                 !isAssistantLoading && (
                  <div className="flex flex-col justify-end min-w-8 py-1">
                    <FeedbackButton 
                      contentIds={[question.chat_id]} 
                      data={{chat_index: index, kind: "chat"}} 
                    />
                  </div>
                )}
              </div>
            )}
            {message.sender === 'user' && (
              <div className="w-4/5 ml-auto border border-blue-500 p-2 rounded-xl bg-white">
                <LatexRenderer text={message.text} />
              </div>
            )}
          </div>
        ))}
      </div>

      <form onSubmit={handleSendMessage} className="mt-2">
        <div className="flex">
          <textarea
            placeholder={placeholderText}
            maxLength={300}
            rows={2}
            value={userMessage}
            onChange={handleMessageChange}
            onKeyDown={handleKeyDown}
            className="flex-grow p-2 border border-gray-300 rounded"
            disabled={isAssistantLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default VideoChat; 