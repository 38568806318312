import React from 'react';

function _getBorderStyle(animal) {
    if (animal === 'salmon') {
        return 'border-orange-300';
    } else if (animal === 'octopus') {
        return 'border-orange-300';
    } else if (animal === 'dolphin') {
        return 'border-blue-300';
    } else {
        return 'border-green-500';
    }
}

/**
 * AnimalCard component that displays an animal image with a circular border
 * and children content below it.
 * 
 * @param {Object} props - Component props
 * @param {string} props.animal - The animal name used to construct the image path
 * @param {React.ReactNode} props.children - Content to display below the image
 * @param {string} [props.className] - Additional CSS classes for the container
 * @returns {JSX.Element} The AnimalCard component
 */
const AnimalCard = ({ animal, children, className = "" }) => {
  return (
    <div className={`w-full rounded-xl p-4 pt-12 mt-10 relative ${className} bg-white border-2 ${_getBorderStyle(animal)}`}>
      <div className="absolute -top-10 left-8">
        <div className={`rounded-full border-2 ${_getBorderStyle(animal)} bg-pastelblue-light p-1 overflow-hidden`}>
          <img 
            src={`/char-${animal}.png`} 
            alt={animal} 
            className="h-16 w-16 object-cover"
          />
        </div>
      </div>
      <div className="mx-2">
        {children}
      </div>
    </div>
  );
};

export default AnimalCard; 