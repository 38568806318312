import React, { useState } from 'react';

export const Tooltip = ({ children, text }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const toggleTooltip = () => {
    setTooltipVisible(!isTooltipVisible);
  };

  return (
    <div className="inline-block text-center">
      <div onClick={toggleTooltip} className="cursor-pointer">
        {children}
      </div>
      {isTooltipVisible && (
        <div className="absolute top-0 left-9 transform w-max bg-gray-700 text-white text-sm rounded p-2 shadow-lg z-10">
          {text}
        </div>
      )}
    </div>
  );
};

const InfoTooltip = ({ text }) => {
  return (
    <Tooltip text={text}>
      <div className="bg-blue-500 text-white rounded-full h-5 w-5 flex items-center justify-center text-sm">
        i
      </div>
    </Tooltip>
  );
};

export default InfoTooltip;
