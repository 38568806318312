import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useError } from '../contexts/ErrorContext';
import StudyPlans from './StudyPlans';
import ToggleButton from './ToggleButton';
import { api_post } from '../api/lfApi';
import { getProfilePicture } from '../api/profile';
import { getStudyPlans, delStudyPlan, postStudyPlanDisplayOrder } from '../api/lfApiStudyPlan';

async function updatePictureUrl(profile, setError) {
    return await api_post(
        '/child/profile/picture_url',
        { picture_url: profile.picture_url },
        setError
    );
}

const Profile = ({ profile, setProfile, onEditProfile, studyPlans, fetchStudyPlans }) => {
    const [isEditPicture, setIsEditPicture] = useState(false);
    const [isArchived, setIsArchived] = useState(false);

    const emojis = [
        '🐠', '🐢', '🐬', '🐙', '🐡', '🐟', '🐳', '🐋',
        '🐶', '🐱', '🐭', '🦊', '🐻', '🐻‍❄️', '🐯', '🦁',
        '🎾', '⚽️', '🏀', '⚾️', '🏐', '🏓', '⛳️', '🛹',
        '🍎', '🍊', '🍓', '🫐', '🥭', '🍌', '🍉', '🥝',
        '🚗', '🚕', '🚙', '🚌', '🚎', '🏎', '🚓', '🚑',
        '🎸', '🎹', '🎷', '🎺', '🎻', '🪕', '🥁', '🪘',
    ];

    const { setError } = useError();

    const navigate = useNavigate();

    const navigateToStudyPlan = (studyPlan) => {
        navigate({
            pathname: `/studyPlan/${studyPlan.id}`,
            search: `?${new URLSearchParams({ 
                backToSettings: 'true',
                isArchived: isArchived ? 'true' : 'false'
            }).toString()}`
        });
    };

    const navigateToAddStudyPlan = () => {
        navigate(`add_study_plan`);
    };

    const onViewStudyPlan = (studyPlan) => {
        navigateToStudyPlan(studyPlan);
    };

    const onAddStudyPlan = () => {
        navigateToAddStudyPlan();
    };

    const onDeleteStudyPlan = async (studyPlan) => {
        const res = await delStudyPlan(profile.username, studyPlan, setError);
        if (res) {
            await fetchStudyPlans();
        }
    };

    const onReorderStudyPlans = async (studyPlans) => {
        const res = await postStudyPlanDisplayOrder(profile.username, studyPlans, setError);
        if (res) {
            await fetchStudyPlans();
        }
    };

    const onPictureUrl = () => {
        setIsEditPicture(true);
    };

    const hasSubscriptionAction = () => {
        return false;
    };

    const onEmoji = async (emoji) => {
        const newProfile = { ...profile, picture_url: `emoji://${emoji}` };
        await updatePictureUrl(newProfile, setError);

        setProfile(newProfile);
        setIsEditPicture(false);
    };

    useEffect(() => {
        if (profile) {
            fetchStudyPlans();
        }
    }, []);

    useEffect(() => {
        if (profile) {
            fetchStudyPlans(isArchived);
        }
    }, [isArchived]);

    return profile === null ? (
        <div>Please login</div>
    ) : (
        <div className="text-left mx-auto max-w-screen-lg">
            <div className="mb-4 flex items-center relative">
                <div className="w-24 h-24 text-xl rounded-full bg-white border border-gray-300 flex items-center justify-center cursor-pointer"
                    onClick={onPictureUrl}>
                    {
                        getProfilePicture(profile, emojis[0])
                    }
                </div>
                {
                    isEditPicture &&
                    <div className="absolute top-6 left-6 right-6 bg-white border border-gray-500 rounded-lg p-4">
                        {
                            emojis.map((emoji, index) => (
                                <div key={index} className="text-xl inline-block cursor-pointer p-2 hover:bg-gray-200 rounded-lg"
                                    onClick={() => onEmoji(emoji)}>
                                    {emoji}
                                </div>
                            ))
                        }
                    </div>
                }
                <div className="text-center ml-2">{profile.first_name}</div>
            </div>

            <h1 className="text-xl font-semibold">Profile</h1>

            <table className="">
                <tbody>
                    <tr>
                        <td className="p-1 pl-0">Fist Name:</td>
                        <td className="p-1">{profile.first_name}</td>
                    </tr>
                    <tr>
                        <td className="p-1 pl-0">Grade Level:</td>
                        <td className="p-1">{profile.grade_level}</td>
                    </tr>
                </tbody>
            </table>

            <div className="flex my-2">
                <button className="bg-blue-500 text-white py-2 px-4 rounded mr-2" onClick={onEditProfile}>
                    Edit profile
                </button>
            </div>

            <h1 className="text-xl font-semibold mt-8">Study plans</h1>

            <StudyPlans
                studyPlans={studyPlans}
                onViewStudyPlan={onViewStudyPlan}
                onDeleteStudyPlan={onDeleteStudyPlan}
                onReorderStudyPlans={onReorderStudyPlans}
                isArchived={isArchived}
                hasSubscriptionAction={hasSubscriptionAction}
            />

            <div className="mt-4 flex">
                <button className={`${isArchived || hasSubscriptionAction() ? "bg-gray-400" : "bg-blue-500"} text-white py-2 px-4 rounded`}
                    onClick={onAddStudyPlan}
                    disabled={isArchived || hasSubscriptionAction()}>
                    Add study plan
                </button>
                <div className="ml-4 flex items-center">
                    <ToggleButton isToggled={isArchived} setIsToggled={setIsArchived} />
                    <span className="ml-1 text-gray-500">Archived</span>
                </div>
            </div>

        </div>
    );
};

export default Profile;
