import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const SearchBox = ({ isSearchVisible, setSearchVisible }) => {
  const [keyword, setKeyword] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchKeyword = searchParams.get('k');
    if (searchKeyword) {
      setSearchVisible(true);
      setKeyword(searchKeyword);
    }
  }, [location.search]);

  useEffect(() => {
    if (isSearchVisible) {
      const searchInput = document.getElementById('search-input');
      if (searchInput) {
        searchInput.focus();
      }
    }
  }, [isSearchVisible]);

  const _setKeyword = (keyword) => {
    setKeyword(keyword);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('k', keyword);
    navigate(`?${searchParams.toString()}`);
  }

  const toggleSearch = async () => {
    const newIsSearchVisible = !isSearchVisible;
    setSearchVisible(newIsSearchVisible);

    if (!newIsSearchVisible) {
      _setKeyword('');
    }
  };

  const handleSearch = async (e) => {
    if (e.key === 'Enter') {
        _setKeyword(e.target.value);
    }
  };

  return (
    <div className="relative inline-block text-center py-2">
      <div
        onClick={toggleSearch}
        className="cursor-pointer"
      >
        🔎
      </div>
      {isSearchVisible && (
        <div className="absolute top-0 left-7 transform w-max rounded p-1 z-9">
          <input 
            type="text" 
            placeholder="Search" 
            onKeyDown={handleSearch}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            id="search-input"
            className="w-full p-1 border border-gray-200"
          />
        </div>
      )}
    </div>
  );
};

export default SearchBox;
