import React from 'react';

const VideoList = ({ children }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-6 w-full">
      {children}
    </div>
  );
};

export default VideoList;
