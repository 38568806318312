import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import StudyLesson from './StudyLesson';
import StudyPlanTopic from './StudyPlanTopic';
import LoadingButton from './LoadingButton';
import EditTopic from './EditTopic';
import { streamStudyPlanTopics } from '../api/lfApiStudyPlan';

const ReviewTopics = ({
    lessonsWithTopics1,
    lessonsWithTopics2,
    plan,
    username,
    setLessonsWithTopics1,
    setLessonsWithTopics2,
    children
}) => {
    const [draggingDraggableId, setDraggingDraggableId] = useState('');
    const [isReorderMode, setIsReorderMode] = useState(false);
    const [selectedTopic, setSelectedTopic] = useState(null); // Format: {list: 'topics1'|'topics2', lessonIndex: number, topicIndex: number}
    
    // Add edit dialog state
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [editingTopic, setEditingTopic] = useState(null);
    const [editingListId, setEditingListId] = useState('');
    const [editingLessonIndex, setEditingLessonIndex] = useState(null);
    const [editingTopicIndex, setEditingTopicIndex] = useState(null);

    // Add nonse state for generating new suggestions
    const maxNonse = 5;
    const [nonse1, setNonse1] = useState(0);
    const [nonse2, setNonse2] = useState(0);

    // Helper function to check if a topic is selected
    const isTopicSelected = (listId, lessonIndex, topicIndex) => {
        return selectedTopic && 
               selectedTopic.list === listId && 
               selectedTopic.lessonIndex === lessonIndex && 
               selectedTopic.topicIndex === topicIndex;
    };

    const incrementNonse1 = () => {
        const n = (nonse1 + 1) % maxNonse;
        setNonse1(n);
        return n;
    };

    const incrementNonse2 = () => {
        const n = (nonse2 + 1) % maxNonse;
        setNonse2(n);
        return n;
    };

    const fetchSuggestedTopic1 = async (lessons, nonse) => {
        await streamStudyPlanTopics(plan, username, lessons, nonse, 'default', setLessonsWithTopics1);
    };

    const fetchSuggestedTopic2 = async (lessons, nonse) => {
        await streamStudyPlanTopics(plan, username, lessons, nonse, 'secondary', setLessonsWithTopics2);
    };

    const handleTopicSelect = (listId, lessonIndex, topicIndex) => {
        // Don't allow selection in reorder mode
        if (isReorderMode) return;
        
        if (isTopicSelected(listId, lessonIndex, topicIndex)) {
            // Unselect if clicking the same topic
            setSelectedTopic(null);
        } else {
            // Select the topic
            setSelectedTopic({ list: listId, lessonIndex, topicIndex });
        }
    };

    const handleEditTopic = (listId, lessonIndex, topicIndex) => {
        const lessons = listId === 'topics1' ? lessonsWithTopics1 : lessonsWithTopics2;
        const topic = lessons[lessonIndex].topics[topicIndex];
        
        // Set up the editing state
        setEditingTopic(topic);
        setEditingListId(listId);
        setEditingLessonIndex(lessonIndex);
        setEditingTopicIndex(topicIndex);
        
        // Open the dialog
        setIsEditDialogOpen(true);
    };
    
    const handleSaveEdit = (updatedTopic) => {
        const setLessons = editingListId === 'topics1' ? setLessonsWithTopics1 : setLessonsWithTopics2;
        const lessons = editingListId === 'topics1' ? lessonsWithTopics1 : lessonsWithTopics2;
        
        const updatedLessons = [...lessons];
        updatedLessons[editingLessonIndex].topics[editingTopicIndex] = updatedTopic;
        
        setLessons(updatedLessons);
        handleCloseEditDialog();
    };
    
    const handleCloseEditDialog = () => {
        setIsEditDialogOpen(false);
        setEditingTopic(null);
        setEditingListId('');
        setEditingLessonIndex(null);
        setEditingTopicIndex(null);
    };

    const handleDeleteTopic = (listId, lessonIndex, topicIndex) => {
        const setLessons = listId === 'topics1' ? setLessonsWithTopics1 : setLessonsWithTopics2;
        const lessons = listId === 'topics1' ? lessonsWithTopics1 : lessonsWithTopics2;
        
        const updatedLessons = [...lessons];
        updatedLessons[lessonIndex].topics.splice(topicIndex, 1);
        
        setLessons(updatedLessons);
        setSelectedTopic(null);
    };

    const onDragTopic = (result) => {
        const { source, destination } = result;

        setDraggingDraggableId('');

        if (!destination) {
            // DroppableIds are in the format '<topics>:<lessonIndex>'
            const sourceDroppableId = source.droppableId.split(':')[0];
            const sourceLessonIndex = parseInt(source.droppableId.split(':')[1], 10);

            // Index is the topic index within the lesson
            const sourceTopicIndex = source.index;

            const { lessons, setLessons } = sourceDroppableId === 'topics1'
                ? { lessons: lessonsWithTopics1, setLessons: setLessonsWithTopics1 }
                : { lessons: lessonsWithTopics2, setLessons: setLessonsWithTopics2 };

            // Remove topic from the source lesson
            const newSourceLessons = [...lessons];
            const sourceLesson = newSourceLessons[sourceLessonIndex];
            sourceLesson.topics.splice(sourceTopicIndex, 1);

            setLessons([...newSourceLessons]);
        } else {
            const getLessonsList = (droppableId) => (droppableId === 'topics1' ? lessonsWithTopics1 : lessonsWithTopics2);
            const setLessonsList = (droppableId, newLessons) => (droppableId === 'topics1' ? setLessonsWithTopics1(newLessons) : setLessonsWithTopics2(newLessons));
        
            const sourceDroppableId = source.droppableId.split(':')[0];
            const destinationDroppableId = destination.droppableId.split(':')[0];

            const sourceLessons = getLessonsList(sourceDroppableId);
            const destinationLessons = getLessonsList(destinationDroppableId);
        
            // DroppableIds are in the format '<topics>:<lessonIndex>'
            const sourceLessonIndex = parseInt(source.droppableId.split(':')[1], 10);
            const destinationLessonIndex = parseInt(destination.droppableId.split(':')[1], 10);

            // Index is the topic index within the lesson
            const sourceTopicIndex = source.index;
            const destinationTopicIndex = destination.index;

            // Remove topic from the source lesson
            const newSourceLessons = [...sourceLessons];
            const sourceLesson = newSourceLessons[sourceLessonIndex];
            const [movedTopic] = sourceLesson.topics.splice(sourceTopicIndex, 1);
        
            if (sourceDroppableId === destinationDroppableId && sourceLessonIndex === destinationLessonIndex) {
                // Moving within the same list
                sourceLesson.topics.splice(destinationTopicIndex, 0, movedTopic);
                setLessonsList(sourceDroppableId, newSourceLessons);
            } else {
                // Moving between lists
                const newDestinationLessons = [...destinationLessons];
                const destinationLesson = newDestinationLessons[destinationLessonIndex];
                destinationLesson.topics.splice(destinationTopicIndex, 0, movedTopic);
            
                setLessonsList(sourceDroppableId, newSourceLessons);
                setLessonsList(destinationDroppableId, newDestinationLessons);
            }
        }
    };

    const onDragUpdate = (update) => {
        setDraggingDraggableId(!update.destination ? update.draggableId : '');
    };

    return (
        <>
            <EditTopic
                topic={editingTopic}
                isOpen={isEditDialogOpen}
                onClose={handleCloseEditDialog}
                onSave={handleSaveEdit}
            />
            
            <div className="flex flex-col md:flex-row gap-4 text-left mt-4">
                <DragDropContext onDragEnd={onDragTopic} onDragUpdate={onDragUpdate}>
                    <div className="flex-1 relative md:pb-4">
                        <hr className="md:hidden" />
                        <div className="flex items-center my-2">
                            <div>Suggestion 1:</div>
                            <LoadingButton imgSrc="/icon-refresh.png" text="Generate new suggestion" 
                                onClick={async () => await fetchSuggestedTopic1(plan.lessons, incrementNonse1())} />
                            <div className="flex-grow"></div>
                            <LoadingButton 
                                imgSrc="/icon-list-reorder3.png" 
                                text={isReorderMode ? "Done Reordering" : "Reorder Topics"}
                                bgColor={isReorderMode ? 'white' : 'transparent'}
                                onClick={() => {
                                    setIsReorderMode(!isReorderMode);
                                    setSelectedTopic(null);
                                }}
                            />
                        </div>
                        {
                            lessonsWithTopics1.map((lesson, lessonIndex) => (
                                <Droppable key={`topics1:${lessonIndex}`} droppableId={`topics1:${lessonIndex}`}>
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps} className="text-gray-500">
                                            <StudyLesson lesson={lesson} index={lessonIndex} isLastLesson={lessonIndex === lessonsWithTopics1.length - 1}>
                                                {
                                                    lesson.topics && lesson.topics.map((topic, topicIndex) => (
                                                        <div key={`lesson1:${topic.id}`} className="text-black relative">
                                                            {isReorderMode ? (
                                                                <Draggable 
                                                                    draggableId={`topics1:${lessonIndex}:${topicIndex}`} 
                                                                    key={`topics1:${lessonIndex}:${topicIndex}`} 
                                                                    index={topicIndex}>
                                                                    {(provided) => (
                                                                        <div ref={provided.innerRef} 
                                                                            {...provided.draggableProps} 
                                                                            className={`${`topics1:${lessonIndex}:${topicIndex}` === draggingDraggableId ? 'opacity-50' : 'opacity-100'}`}>
                                                                            <div className="pr-10">
                                                                                <StudyPlanTopic 
                                                                                    topic={topic} 
                                                                                    index={topicIndex}
                                                                                    topicType="current"
                                                                                />
                                                                            </div>
                                                                            
                                                                            <div 
                                                                                {...provided.dragHandleProps}
                                                                                className="absolute top-0 bottom-0 right-2 flex items-center justify-center h-full cursor-grab"
                                                                            >
                                                                                <span className="text-2xl text-gray-500 px-2">≡</span>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ) : (
                                                                <div className={`cursor-pointer ${
                                                                    isTopicSelected('topics1', lessonIndex, topicIndex) ? 'pr-20' : ''
                                                                }`}
                                                                    onClick={() => handleTopicSelect('topics1', lessonIndex, topicIndex)}>
                                                                    <StudyPlanTopic 
                                                                        topic={topic} 
                                                                        index={topicIndex}
                                                                        topicType={isTopicSelected('topics1', lessonIndex, topicIndex) ? 
                                                                            '' : 'current'} 
                                                                    />
                                                                    
                                                                    {isTopicSelected('topics1', lessonIndex, topicIndex) && (
                                                                        <div className="absolute top-0 right-2 flex space-x-1 z-10">
                                                                            <LoadingButton 
                                                                                imgSrc="/icon-edit.png" 
                                                                                text="Edit"
                                                                                bgColor="white"
                                                                                onClick={() => handleEditTopic('topics1', lessonIndex, topicIndex)}
                                                                            />
                                                                            <LoadingButton 
                                                                                imgSrc="/icon-trash.png" 
                                                                                text="Delete"
                                                                                bgColor="white"
                                                                                onClick={() => handleDeleteTopic('topics1', lessonIndex, topicIndex)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))
                                                }
                                                {provided.placeholder}
                                            </StudyLesson>
                                        </div>
                                    )}
                                </Droppable>
                            ))
                        }
                        {React.Children.toArray(children)[0]}
                    </div>

                    <div className="flex-1 relative pb-4">
                        <hr className="md:hidden" />
                        <div className="flex items-center my-2">
                            <div>Suggestion 2:</div>
                            <LoadingButton imgSrc="/icon-refresh.png" text="Generate new suggestion" 
                                onClick={async () => await fetchSuggestedTopic2(plan.lessons, incrementNonse2())} />
                            <div className="flex-grow"></div>
                            <LoadingButton 
                                imgSrc="/icon-list-reorder3.png" 
                                text={isReorderMode ? "Done Reordering" : "Reorder Topics"}
                                bgColor={isReorderMode ? 'white' : 'transparent'}
                                onClick={() => {
                                    setIsReorderMode(!isReorderMode);
                                    setSelectedTopic(null);
                                }}
                            />
                        </div>
                        {
                            lessonsWithTopics2.map((lesson, lessonIndex) => (
                                <Droppable key={`topics2:${lessonIndex}`} droppableId={`topics2:${lessonIndex}`}>
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps} className="text-gray-500">
                                            <StudyLesson lesson={lesson} index={lessonIndex} isLastLesson={lessonIndex === lessonsWithTopics2.length - 1}>
                                                {
                                                    lesson.topics && lesson.topics.map((topic, topicIndex) => (
                                                        <div key={`lesson2:${topic.id}`} className="text-black relative">
                                                            {isReorderMode ? (
                                                                <Draggable 
                                                                    draggableId={`topics2:${lessonIndex}:${topicIndex}`} 
                                                                    key={`topics2:${lessonIndex}:${topicIndex}`} 
                                                                    index={topicIndex}>
                                                                    {(provided) => (
                                                                        <div ref={provided.innerRef} 
                                                                            {...provided.draggableProps} 
                                                                            className={`${`topics2:${lessonIndex}:${topicIndex}` === draggingDraggableId ? 'opacity-50' : 'opacity-100'}`}>
                                                                            <div className="pr-10">
                                                                                <StudyPlanTopic 
                                                                                    topic={topic} 
                                                                                    index={topicIndex}
                                                                                    topicType="current"
                                                                                />
                                                                            </div>
                                                                            
                                                                            <div 
                                                                                {...provided.dragHandleProps}
                                                                                className="absolute top-0 bottom-0 right-2 flex items-center justify-center h-full cursor-grab"
                                                                            >
                                                                                <span className="text-2xl text-gray-500 px-2">≡</span>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ) : (
                                                                <div className={`cursor-pointer ${
                                                                    isTopicSelected('topics2', lessonIndex, topicIndex) ? 'pr-20' : ''
                                                                }`}
                                                                    onClick={() => handleTopicSelect('topics2', lessonIndex, topicIndex)}>
                                                                    <StudyPlanTopic 
                                                                        topic={topic} 
                                                                        index={topicIndex}
                                                                        topicType={isTopicSelected('topics2', lessonIndex, topicIndex) ? 
                                                                            '' : 'current'} 
                                                                    />
                                                                    
                                                                    {isTopicSelected('topics2', lessonIndex, topicIndex) && (
                                                                        <div className="absolute top-0 right-2 flex space-x-1 z-10">
                                                                            <LoadingButton 
                                                                                imgSrc="/icon-edit.png" 
                                                                                text="Edit"
                                                                                bgColor="white"
                                                                                onClick={() => handleEditTopic('topics2', lessonIndex, topicIndex)}
                                                                            />
                                                                            <LoadingButton 
                                                                                imgSrc="/icon-trash.png" 
                                                                                text="Delete"
                                                                                bgColor="white"
                                                                                onClick={() => handleDeleteTopic('topics2', lessonIndex, topicIndex)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))
                                                }
                                                {provided.placeholder}
                                            </StudyLesson>
                                        </div>
                                    )}
                                </Droppable>
                            ))
                        }
                        {React.Children.toArray(children)[1]}
                    </div>
                </DragDropContext>
            </div>
        </>
    );
};

export default ReviewTopics; 