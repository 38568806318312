import { useLocation } from 'react-router-dom';
import { getProfilePicture } from '../api/profile';

function Header({ childProfile, score, studyPlans, onChildProfile, onStudyPlan }) {
    const location = useLocation();

    function getPageType() {
        const values = location.pathname.split('/');
        return values[1];
    }

    function getStudyPlanId() {
        const values = location.pathname.split('/');
        return values[1] === 'feed' 
            ? new URLSearchParams(location.search).get('sp')
            : '';
    }

    function getStyle(studyPlanId) {
        return studyPlanId === getStudyPlanId() ? 'border border-blue-600 border-2' : '';
    }

    return <nav className="bg-sky pt-4">
        <div className={`flex justify-between mx-auto max-w-screen-lg ${getPageType() === "video" ? "mb-4" : "mb-2"}`}>
            {/* Left-aligned content */}
            <div>
            </div>
        
            {/* Right-aligned div */}
            <div className="ml-auto flex flex-col">
                <div className="mr-4" onClick={onChildProfile}>
                    {
                    score > 0 && <span className="bg-yellow-300 rounded-2xl border p-2">
                        ⭐️ {score}
                    </span>
                    }
                    { 
                    childProfile 
                        ? <span className="cursor-pointer bg-white rounded-2xl border border-white p-2 ml-1">
                            {childProfile.first_name + ' ' + getProfilePicture(childProfile, '🐠')}
                        </span>
                        : '' 
                    }
                </div>
            </div>
        </div>

        {
            getPageType() !== 'video' && <div className="flex justify-between">
                {/* Left-aligned content */}
                <div className="w-1/3 md:w-1/4 2xl:w-1/5 flex items-end">
                    <img src="/bg-water-top-left7.png" alt="" className="w-full h-full" />
                </div>

                {/* Center-aligned content */}
                <div className="z-10 w-1/3 md:w-1/2 2xl:w-3/5 flex items-center justify-center">
                {
                    getStudyPlanId() && <div className="flex justify-center gap-4">
                        {
                        studyPlans && studyPlans.map((studyPlan) => (
                            <div key={studyPlan.id} className="text-center">
                                <div className={`w-24 h-24 rounded-full bg-white flex items-center justify-center mx-auto cursor-pointer ${getStyle(studyPlan.id)}`}
                                        onClick={() => onStudyPlan(studyPlan.id)}>
                                    { studyPlan.emoji || '🐠' }
                                </div>
                                <div className="mt-2">
                                    {studyPlan.subject}
                                </div>
                            </div>
                        ))
                        }
                    </div>
                }
                </div>

                {/* Right-aligned div */}
                <div className="ml-auto w-1/3 md:w-1/4 2xl:w-1/5 flex items-end">
                    <img src="/bg-water-top-right.png" alt="" className="w-full h-full" />
                </div>
            </div>
        }
        
    </nav>;    
}

export default Header;
