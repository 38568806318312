import React, { useState } from 'react';

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <div className="flex justify-center mb-4">
        <div className="border border-gray-300 bg-white rounded-2xl flex flex-row">
          {React.Children.map(children, (child, index) => (
            <div
              key={index}
              className={`px-4 py-2 text-sm cursor-pointer ${
                activeTab === index 
                  ? "text-white bg-blue-500 " + (index === 0 ? "rounded-l-2xl" : "") + (index === React.Children.count(children) - 1 ? " rounded-r-2xl" : "")
                  : "text-gray-600"
              }`}
              onClick={() => setActiveTab(index)}
            >
              {child.props.label}
            </div>
          ))}
        </div>
      </div>
      <div className="py-4">
        {React.Children.map(children, (child, index) => (
          <div key={index} style={{ display: activeTab === index ? 'block' : 'none' }}>
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
