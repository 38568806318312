import React, { useState } from 'react';
import LoadingIndicator from './LoadingIndicator';

const LoadingButton = ({ onClick, text, imgSrc = null, isLoading = false, disabled = false, bgColor = 'transparent', ...otherProps }) => {
    const [isInternalLoading, setIsInternalLoading] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    function isLoadingNow() {
        return isLoading || isInternalLoading;
    }

    async function handleClick(e) {
        setIsInternalLoading(true);
        await onClick(e);
        setIsInternalLoading(false);
    }

    return <div className="flex items-center">
        {
            imgSrc 
                ? <div 
                    className="cursor-pointer" 
                    onClick={(e) => handleClick(e)}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <img src={imgSrc}
                        className={`w-8 h-8 p-1 border border-white rounded-lg`}
                        style={{ backgroundColor: isHovered ? '#e5e7eb' /* bg-gray-200 */ : bgColor }}
                        alt={text} />
                </div> : <button 
                    className={`${isLoadingNow() || disabled ? "bg-gray-400" : "bg-blue-500"} text-white py-2 px-4 rounded whitespace-nowrap`} 
                    onClick={(e) => handleClick(e)} 
                    disabled={isLoadingNow() || disabled}
                    {...otherProps}>
                    {text}
                </button>
        }
        {
            isLoadingNow() && <LoadingIndicator />
        }
    </div>;
};

export default LoadingButton;
