import React, { useState } from 'react';
import yted from '../api/lfApi';

const LessonPlanAdd = ({ onOk, onCancel }) => {
    const [plan, setPlan] = useState({ subject: '', description: '', goal: '' });
    const [topics1, setTopics1] = useState([]);
    const [topics2, setTopics2] = useState([]);

    const getLessonPlan = (topics) => {
        return {
            subject: plan.subject,
            description: plan.description,
            goal: plan.goal,
            topics: topics,
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPlan(prevState => ({ ...prevState, [name]: value }));
    };

    const handleGenerateTopics = async () => {
        const params = {
            subject: plan.subject,
            description: plan.description,
            goal: plan.goal,
        }
        const futures = [
            yted.post('/lesson_plan', params), yted.post('/lesson_plan', params)
        ];
        const responses = await Promise.all(futures);
        if (responses[0].status === 200) {
            setTopics1(responses[0].data.topics);
        }
        if (responses[1].status === 200) {
            setTopics2(responses[1].data.topics);
        }
    };

    return <div>
        <h1 className="text-xl">Add Interest</h1>
        <table className="table-auto w-full">
            <colgroup>
                <col className="w-1/4" />
                <col className="w-3/4" />
            </colgroup>
            <tbody>
                <tr>
                    <td className="text-right p-1">Subject</td>
                    <td className="text-left p-1">
                        <input
                            type="text"
                            className="full-w border border-gray-300 p-1"
                            name="subject"
                            value={plan.subject}
                            onChange={handleChange} />
                    </td>
                </tr>
                <tr>
                    <td className="text-right align-top p-1">Lesson plan description</td>
                    <td className="p-1">
                        <textarea
                            className="border border-gray-300 w-full p-1"
                            name="description"
                            value={plan.description}
                            onChange={handleChange} />
                    </td>
                </tr>
                <tr>
                    <td className="text-right align-top p-1">Goal</td>
                    <td className="p-1">
                        <textarea
                            className="border border-gray-300 w-full p-1"
                            name="goal"
                            value={plan.goal}
                            onChange={handleChange} />
                    </td>
                </tr>
                {
                    topics1.length > 0 && <tr>
                        <td className="text-right align-top p-1">Topics</td>
                        <td className="p-1 text-left">
                            <div className="flex">
                                <div>
                                    <div>Suggestion 1:</div>
                                    <ol className="list-decimal list-outside pl-8">
                                        {
                                            topics1.map((topic, index) => (
                                                <li key={index}>
                                                    {topic.topic}: {topic.explanation}
                                                </li>
                                            ))
                                        }
                                    </ol>
                                </div>
                                <div>
                                    <div>Suggestion 2:</div>
                                    <ol className="list-decimal list-outside pl-8">
                                        {
                                            topics2.map((topic, index) => (
                                                <li key={index}>
                                                    {topic.topic}: {topic.explanation}
                                                </li>
                                            ))
                                        }
                                    </ol>
                                </div>
                            </div>
                        </td>
                    </tr>
                }
            </tbody>
        </table>

        <div className="flex justify-center gap-2 mt-8">
            <button className="bg-blue-500 text-white py-2 px-4 rounded" onClick={handleGenerateTopics}>
                Generate lesson plan
            </button>
            {
                topics1.length > 0 && <button className="bg-blue-500 text-white py-2 px-4 rounded ml-12" onClick={() => onOk(getLessonPlan(topics1))}>
                    Accept suggestion 1
                </button>
            }
            {
                topics2.length > 0 && <button className="bg-blue-500 text-white py-2 px-4 rounded" onClick={() => onOk(getLessonPlan(topics2))}>
                    Accept suggestion 2
                </button>
            }
            <button className="bg-blue-500 text-white py-2 px-4 rounded" onClick={onCancel}>
                Cancel
            </button>
        </div>
    </div>
    ;
};

export default LessonPlanAdd;
