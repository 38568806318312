import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import StudyLesson from './StudyLesson';
import StudyPlanTopic from './StudyPlanTopic';
import LoadingButton from './LoadingButton';
import AddNewLesson from './AddNewLesson';
import EditLesson from './EditLesson';
import EditTopic from './EditTopic';
import { streamStudyPlan, streamTopics, streamLesson } from '../api/lfApiStudyPlan';

function getStudyPlan(plan, lessons) {
    return {
        id: plan.id,
        version: plan.version,
        subject: plan.subject,
        goal: plan.goal,
        previous_knowledge: plan.previous_knowledge,
        num_lessons: parseInt(plan.num_lessons),
        needs: plan.needs,
        emoji: plan.emoji,
        lessons: lessons,
    }
};

const EditStudyPlan = ({ username, lessonPlan, onOk, onCancel }) => {
    const [plan, setPlan] = useState(lessonPlan || {
        subject: '',
        goal: '',
        previous_knowledge: '',
        num_lessons: '5',
        needs: '',
        emoji: '',
    });
    const [isPreviewEnabled, setIsPreviewEnabled] = useState(false);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [lessons1, setLessons1] = useState(lessonPlan ? lessonPlan.lessons : []);
    const [lessons2, setLessons2] = useState([]);

    const [isAddLessonDialogOpen, setIsAddLessonDialogOpen] = useState(false);
    const [isReorderMode, setIsReorderMode] = useState(false);
    const [selectedLesson, setSelectedLesson] = useState(null); // 'lesson1:0' or 'lesson2:0'
    const [selectedTopic, setSelectedTopic] = useState(null); // 'lesson1:0:0' or 'lesson2:0:0'
    const [isEditLessonDialogOpen, setIsEditLessonDialogOpen] = useState(false);
    const [isEditTopicDialogOpen, setIsEditTopicDialogOpen] = useState(false);

    const minNumLessons = 3;
    const maxNumLessons = 8;

    const maxNonse = 5;
    const [nonse, setNonse] = useState(0);

    const [draggingDraggableId, setDraggingDraggableId] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPlan(prevState => ({ ...prevState, [name]: value }));

        if (name === "emoji") {
            setIsSaveEnabled(true);
        } else {
            setIsPreviewEnabled(true);
        }
    };

    const incrementNonse = () => {
        const n = (nonse + 1) % maxNonse
        setNonse(n);
        return n;
    };

    const incrementNumLessons = async (incr) => {
        const currentNumLessons = parseInt(plan.num_lessons);
        const newNumLessons = currentNumLessons + incr;
        if (newNumLessons < minNumLessons || newNumLessons > maxNumLessons) {
            return;
        }

        const newPlan = { ...plan, num_lessons: newNumLessons.toString() };
        setPlan(newPlan);
        await onPreviewStudyPlans(newPlan, nonse);
    };

    const onPreviewStudyPlans = async (studyPlan, nonse) => {
        if (!isPreviewEnabled) return;

        const llm_name = 'default';
        const plan = {
            subject: studyPlan.subject,
            goal: studyPlan.goal,
            previous_knowledge: studyPlan.previous_knowledge,
            needs: studyPlan.needs,
        }
        const planProp = { nonse, num_lessons: studyPlan.num_lessons }
        const lessons = await streamStudyPlan(plan, username, planProp, llm_name, setLessons2);

        let topicsFuture = [];
        if (!lessons1[0].topics || lessons1[0].topics.length === 0)
            topicsFuture.push(streamTopics(plan, username, lessons1, setLessons1));
        topicsFuture.push(streamTopics(plan, username, lessons, setLessons2, llm_name));

        await Promise.all(topicsFuture);
    };

    const onDragUpdate = (update) => {
        setDraggingDraggableId(!update.destination ? update.draggableId : '');
    };
    
    const onDragEnd = (result) => {
        setDraggingDraggableId('');

        if (result.type === "lessons")
            onDragLesson(result);
        else
            onDragTopic(result);

        setIsSaveEnabled(true);
    };

    const onDragLesson = (result) => {
        const { source, destination } = result;

        if (!destination || source.droppableId === destination.droppableId) {
            const { lessons, setLessons } = source.droppableId === 'lesson1' 
                ? { lessons: lessons1, setLessons: setLessons1 }
                : { lessons: lessons2, setLessons: setLessons2 };

            const [removed] = lessons.splice(source.index, 1);
            if (destination)
                lessons.splice(destination.index, 0, removed);
            setLessons([...lessons]);
        } else {
            const { sourceLessons, setSourceLessons } = source.droppableId === 'lesson1' 
                ? { sourceLessons: lessons1, setSourceLessons: setLessons1 } 
                : { sourceLessons: lessons2, setSourceLessons: setLessons2 };
            const { destinationLessons, setDestinationLessons } = destination.droppableId === 'lesson1' 
                ? { destinationLessons: lessons1, setDestinationLessons: setLessons1 }
                : { destinationLessons: lessons2, setDestinationLessons: setLessons2 };

            const [removed] = sourceLessons.splice(source.index, 1);
            destinationLessons.splice(destination.index, 0, removed);

            setSourceLessons([...sourceLessons]);
            setDestinationLessons([...destinationLessons]);
        }
    }

    const onDragTopic = (result) => {
        const { source, destination } = result;
    
        if (!destination) {
            // DroppableIds are in the format '<topics>:<lessonIndex>'
            const sourceDroppableId = source.droppableId.split(':')[0];
            const sourceLessonIndex = parseInt(source.droppableId.split(':')[1], 10);

            // Index is the topic index within the lesson
            const sourceTopicIndex = source.index;

            const { lessons, setLessons } = sourceDroppableId === 'lesson1'
                ? { lessons: lessons1, setLessons: setLessons1 }
                : { lessons: lessons2, setLessons: setLessons2 };

            // Remove topic from the source lesson
            const newSourceLessons = [...lessons];
            const sourceLesson = newSourceLessons[sourceLessonIndex];
            sourceLesson.topics.splice(sourceTopicIndex, 1);

            setLessons([...newSourceLessons]);
        } else {
            const getLessonsList = (droppableId) => (droppableId === 'lesson1' ? lessons1 : lessons2);
            const setLessonsList = (droppableId, newLessons) => (droppableId === 'lesson1' ? setLessons1(newLessons) : setLessons2(newLessons));
        
            // DroppableIds are in the format '<topics>:<lessonIndex>'
            const sourceDroppableId = source.droppableId.split(':')[0];
            const destinationDroppableId = destination.droppableId.split(':')[0];
            const sourceLessonIndex = parseInt(source.droppableId.split(':')[1], 10);
            const destinationLessonIndex = parseInt(destination.droppableId.split(':')[1], 10);

            const sourceLessons = getLessonsList(sourceDroppableId);
            const destinationLessons = getLessonsList(destinationDroppableId);
        
            // Index is the topic index within the lesson
            const sourceTopicIndex = source.index;
            const destinationTopicIndex = destination.index;

            // Remove topic from the source lesson
            const newSourceLessons = [...sourceLessons];
            const sourceLesson = newSourceLessons[sourceLessonIndex];
            const [movedTopic] = sourceLesson.topics.splice(sourceTopicIndex, 1);
        
            if (sourceDroppableId === destinationDroppableId && sourceLessonIndex === destinationLessonIndex) {
                // Moving within the same list
                sourceLesson.topics.splice(destinationTopicIndex, 0, movedTopic);
                setLessonsList(sourceDroppableId, newSourceLessons);
            } else {
                // Moving between lists
                const newDestinationLessons = [...destinationLessons];
                const destinationLesson = newDestinationLessons[destinationLessonIndex];
                destinationLesson.topics.splice(destinationTopicIndex, 0, movedTopic);
            
                setLessonsList(sourceDroppableId, newSourceLessons);
                setLessonsList(destinationDroppableId, newDestinationLessons);
            }
        }
    };

    const onLessonClick = (source, lessonIndex, e) => {
        if (!isReorderMode) {
            if (e) e.stopPropagation();

            const newSelectedLesson = `${source}:${lessonIndex}`;
            setSelectedLesson(selectedLesson === newSelectedLesson ? null : newSelectedLesson);
            setSelectedTopic(null);
        }
    };

    const onTopicClick = (source, lessonIndex, topicIndex, e) => {
        if (!isReorderMode) {
            if (e) e.stopPropagation();

            const newSelectedTopic = `${source}:${lessonIndex}:${topicIndex}`;
            setSelectedTopic(selectedTopic === newSelectedTopic ? null : newSelectedTopic);
            setSelectedLesson(null);
        }
    };

    const onLessonEdit = (source, lessonIndex, e) => {
        if (e) e.stopPropagation();
        setIsEditLessonDialogOpen(true);
    };

    const onLessonDelete = (source, lessonIndex, e) => {
        if (e) e.stopPropagation();
        const { lessons, setLessons } = source === 'lesson1' 
            ? { lessons: lessons1, setLessons: setLessons1 }
            : { lessons: lessons2, setLessons: setLessons2 };
        
        const newLessons = [...lessons];
        newLessons.splice(lessonIndex, 1);
        setLessons(newLessons);
        setSelectedLesson(null);
        setIsSaveEnabled(true);
    };

    const onTopicEdit = (source, lessonIndex, topicIndex, e) => {
        if (e) e.stopPropagation();
        setIsEditTopicDialogOpen(true);
    };

    const onTopicDelete = (source, lessonIndex, topicIndex, e) => {
        if (e) e.stopPropagation();
        const { lessons, setLessons } = source === 'lesson1' 
            ? { lessons: lessons1, setLessons: setLessons1 }
            : { lessons: lessons2, setLessons: setLessons2 };
        
        const newLessons = [...lessons];
        newLessons[lessonIndex].topics.splice(topicIndex, 1);
        setLessons(newLessons);
        setSelectedTopic(null);
        setIsSaveEnabled(true);
    };

    const handleAddLesson = () => {
        setIsAddLessonDialogOpen(true);
    };

    const handleAddNewLesson = async (lessonGoals) => {
        await streamLesson(plan, lessonGoals, username, lessons1, setLessons1);
        setIsSaveEnabled(true);
    };

    const toggleReorderMode = () => {
        setIsReorderMode(!isReorderMode);
        // Clear selections when toggling reorder mode
        setSelectedLesson(null);
        setSelectedTopic(null);
    };

    const handleEditLesson = (editedLesson) => {
        if (selectedLesson) {
            const [source, lessonIndex] = selectedLesson.split(':');
            const { lessons, setLessons } = source === 'lesson1' 
                ? { lessons: lessons1, setLessons: setLessons1 }
                : { lessons: lessons2, setLessons: setLessons2 };
            
            const newLessons = [...lessons];
            newLessons[lessonIndex] = { ...newLessons[lessonIndex], ...editedLesson };
            setLessons(newLessons);
            setIsSaveEnabled(true);
        }

        setIsEditLessonDialogOpen(false);
    };

    const handleEditTopic = (editedTopic) => {
        if (selectedTopic) {
            const [source, lessonIndex, topicIndex] = selectedTopic.split(':');
            const { lessons, setLessons } = source === 'lesson1' 
                ? { lessons: lessons1, setLessons: setLessons1 }
                : { lessons: lessons2, setLessons: setLessons2 };
            
            const newLessons = [...lessons];
            newLessons[lessonIndex].topics[topicIndex] = { ...newLessons[lessonIndex].topics[topicIndex], ...editedTopic };
            setLessons(newLessons);
            setIsSaveEnabled(true);
        }
        setIsEditTopicDialogOpen(false);
    };

    const getSelectedLesson = () => {
        if (!selectedLesson) return null;
        
        const [source, lessonIndex] = selectedLesson.split(':');
        const lessons = source === 'lesson1' ? lessons1 : lessons2;
        return lessons[lessonIndex];
    };

    const getSelectedTopic = () => {
        if (!selectedTopic) return null;
        
        const [source, lessonIndex, topicIndex] = selectedTopic.split(':');
        const lessons = source === 'lesson1' ? lessons1 : lessons2;
        return lessons[lessonIndex].topics[topicIndex];
    };

    const isTopicSelected = (source, lessonIndex, topicIndex) => {
        return selectedTopic === `${source}:${lessonIndex}:${topicIndex}`;
    };

    const renderTopic = (source, lessonIndex, topicIndex, topic, provided) => {
        return (
            <div key={`${source}:${topic.id}`}>
                <Draggable draggableId={`${source}:${lessonIndex}:${topicIndex}`} 
                        key={`${lessonIndex}:${topicIndex}`} 
                        index={topicIndex}
                        isDragDisabled={!isReorderMode}>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}
                            className={`${`${source}:${lessonIndex}:${topicIndex}` === draggingDraggableId ? 'opacity-50' : 'opacity-100'} relative`}
                            onClick={(e) => onTopicClick(source, lessonIndex, topicIndex, e)}>
                            <div className={`flex items-center relative ${
                                    isTopicSelected(source, lessonIndex, topicIndex) ? 'pr-20' : ''
                                }`}>
                                <StudyPlanTopic 
                                    topic={topic} 
                                    index={topicIndex}
                                    topicType={selectedTopic === `${source}:${lessonIndex}:${topicIndex}` ? '' : 'current'}
                                />
                                <div className="flex-grow"></div>
                                
                                {selectedTopic === `${source}:${lessonIndex}:${topicIndex}` && !isReorderMode && (
                                    <div className="absolute top-0 right-2 flex space-x-1 ml-2">
                                        <LoadingButton 
                                            imgSrc="/icon-edit.png" 
                                            text="Edit"
                                            bgColor="white"
                                            onClick={(e) => onTopicEdit(source, lessonIndex, topicIndex, e)}
                                        />
                                        <LoadingButton 
                                            imgSrc="/icon-trash.png" 
                                            text="Delete"
                                            bgColor="white"
                                            onClick={(e) => onTopicDelete(source, lessonIndex, topicIndex, e)}
                                        />
                                    </div>
                                )}
                                
                                {isReorderMode && (
                                    <div 
                                        {...provided.dragHandleProps}
                                        className="flex items-center justify-center cursor-grab ml-2"
                                    >
                                        <span className="text-2xl text-gray-500 px-2">≡</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </Draggable>
            </div>
        );
    };

    const renderLesson = (source, lesson, lessonIndex, lessons) => {
        return (
            <Draggable key={`${source}:${lessonIndex}`} draggableId={`${source}:${lessonIndex}`} index={lessonIndex} isDragDisabled={!isReorderMode}>
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}
                        className={`${`${source}:${lessonIndex}` === draggingDraggableId ? 'opacity-50' : 'opacity-100'} relative ${isReorderMode ? '' : 'cursor-pointer'}`}
                        onClick={(e) => onLessonClick(source, lessonIndex, e)}>
                        <StudyLesson 
                            lesson={lesson} 
                            index={lessonIndex} 
                            isLastLesson={lessonIndex === lessons.length - 1}
                            lessonBoxStyle={selectedLesson === `${source}:${lessonIndex}` ? 'bg-blue-500 text-white border-blue-500' : 'bg-white'}
                            isReorderMode={isReorderMode}
                        >
                            <Droppable key={`${source}:${lessonIndex}`} droppableId={`${source}:${lessonIndex}`} type="topics">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                {
                                    lesson.topics && lesson.topics.map((topic, topicIndex) => 
                                        renderTopic(source, lessonIndex, topicIndex, topic, provided)
                                    )
                                }
                                {provided.placeholder}
                                </div>
                            )}
                            </Droppable>
                        </StudyLesson>                                                        
                        {selectedLesson === `${source}:${lessonIndex}` && !isReorderMode && (
                            <div className="absolute top-2 right-2 flex space-x-1">
                                <LoadingButton 
                                    imgSrc="/icon-edit.png" 
                                    text="Edit"
                                    bgColor="white"
                                    onClick={(e) => onLessonEdit(source, lessonIndex, e)}
                                />
                                <LoadingButton 
                                    imgSrc="/icon-trash.png" 
                                    text="Delete"
                                    bgColor="white"
                                    onClick={(e) => onLessonDelete(source, lessonIndex, e)}
                                />
                            </div>
                        )}
                        {isReorderMode && (
                            <div 
                                {...provided.dragHandleProps}
                                className="absolute top-2 right-0 cursor-grab"
                            >
                                <span className="text-2xl text-gray-500 px-2">≡</span>
                            </div>
                        )}
                    </div>
                )}
            </Draggable>
        );
    };

    return <div className='text-left mx-auto max-w-screen-lg'>
        <h1 className="text-xl">
            {lessonPlan ? "Edit study plan" : "Add study plan"}
        </h1>

        {
            !lessonPlan && <div>
                <div>
                    A study plan is a personalized learning plan designed to guide your child through their learning goals.
                </div>
                <div>
                    Create a new study plan by entering the following information:
                </div>
            </div>
        }

        <table className="table-auto w-full mt-2">
            <colgroup>
                <col />
                <col className="w-full" />
            </colgroup>
            <tbody>
                <tr>
                    <td className="text-right p-1">Subject</td>
                    <td className="text-left px-1">
                        <div className="flex flex-row items-center gap-2 py-1">
                            <input
                                type="text"
                                className="w-full border border-gray-300 p-1"
                                name="subject"
                                placeholder="eg, Math, Science, English, etc."
                                value={plan.subject}
                                maxLength={30}
                                onChange={handleChange} />
                            <span className="ml-2 hidden sm:block">Emoji</span>
                            <input type="text"
                                className="border border-gray-300 w-20 p-1 hidden sm:block"
                                name="emoji"
                                maxLength={2}
                                value={plan.emoji}
                                onChange={handleChange} />
                        </div>
                    </td>
                </tr>
                <tr className="sm:hidden">
                    <td className="text-right align-top p-1">Emoji</td>
                    <td className="px-1">
                        <input type="text"
                            className="border border-gray-300 w-20 p-1"
                            name="emoji"
                            maxLength={2}
                            value={plan.emoji}
                            onChange={handleChange} />
                    </td>
                </tr>
                <tr>
                    <td className="text-right align-top p-1">Learning goals</td>
                    <td className="px-1">
                        <textarea
                            className="border border-gray-300 w-full p-1 h-[5rem] sm:h-[4rem]"
                            name="goal"
                            placeholder="eg, Master 6th grade math concepts, develop reading comprehension skills and build vocabulary, etc."
                            value={plan.goal}
                            maxLength={120}
                            onChange={handleChange} />
                    </td>
                </tr>
                <tr>
                    <td className="text-right align-top p-1 md:whitespace-nowrap">Previous knowledge</td>
                    <td className="px-1">
                        <textarea
                            className="border border-gray-300 w-full p-1 h-[5rem] sm:h-[4rem]"
                            name="previous_knowledge"
                            placeholder="eg, Fully understands 5th grade math, can read at grade level but struggles with comprehension of more complex texts, etc."
                            value={plan.previous_knowledge}
                            maxLength={120}
                            onChange={handleChange} />
                    </td>
                </tr>
            </tbody>
        </table>

        <div className="flex flex-row w-full justify-center items-center gap-2 mt-2">
            <div className="flex gap-2">
                {
                    lessons2.length === 0 && 
                    <LoadingButton 
                        text="Save"
                        disabled={!isSaveEnabled}
                        onClick={() => onOk(getStudyPlan(plan, lessons1))}
                    />
                }
                <button className="bg-blue-500 text-white py-2 px-4 rounded"
                    onClick={onCancel}>
                    Cancel
                </button>
            </div>
            <div className={`flex items-center gap-2 ${!isPreviewEnabled || lessons2.length > 0 ? 'hidden' : ''}`}>
                <div className="flex h-full mx-1">
                    <div>&nbsp;</div>
                    <div className="border-l border-gray-300 h-full">&nbsp;</div>
                </div>

                <LoadingButton 
                    text="Suggest new study plan" 
                    onClick={() => onPreviewStudyPlans(plan, incrementNonse())} 
                    disabled={!isPreviewEnabled} />
            </div>
        </div>

        <div className="text-sm text-gray-600 mt-6">
            Use the + button below to add a new lesson. 
        </div>
        
        <AddNewLesson 
            isDialogOpen={isAddLessonDialogOpen}
            setIsDialogOpen={setIsAddLessonDialogOpen}
            onAddLesson={handleAddNewLesson}
        />
        <EditLesson
            lesson={getSelectedLesson()}
            isOpen={isEditLessonDialogOpen}
            onClose={() => setIsEditLessonDialogOpen(false)}
            onSave={handleEditLesson}
        />
        <EditTopic
            topic={getSelectedTopic()}
            isOpen={isEditTopicDialogOpen}
            onClose={() => setIsEditTopicDialogOpen(false)}
            onSave={handleEditTopic}
        />
        
        {
            lessons1.length > 0 && <div className="mt-6">
                <h1 className="text-lg">
                    {
                    lessons2.length > 0 
                        ? <span>Study plans</span>
                        : <div className="flex items-center gap-1">
                            <div>Current study plan</div>
                            <LoadingButton 
                                imgSrc="/icon-plus.png" 
                                text="Add lesson" 
                                onClick={handleAddLesson}
                            />
                            <div className="flex-grow"></div>
                            <LoadingButton 
                                imgSrc="/icon-list-reorder3.png" 
                                text={isReorderMode ? "Done Reordering" : "Reorder"}
                                bgColor={isReorderMode ? 'white' : 'transparent'}
                                onClick={toggleReorderMode}
                            />
                        </div>
                    }
                </h1>

                <div className="flex flex-col md:flex-row gap-4 text-left mt-2">
                    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
                        <div className="flex-1">
                            {
                                lessons2.length > 0 && <>
                                    <hr className="md:hidden mb-2" />
                                    <div className="flex items-center mb-2">
                                        <div className="my-2">Current study plan:</div>
                                        <LoadingButton 
                                            imgSrc="/icon-plus.png" 
                                            text="Add lesson" 
                                            onClick={handleAddLesson}
                                        />
                                        <div className="flex-grow"></div>
                                        <LoadingButton 
                                            imgSrc="/icon-list-reorder3.png" 
                                            text={isReorderMode ? "Done Reordering" : "Reorder"}
                                            bgColor={isReorderMode ? 'white' : 'transparent'}
                                            onClick={toggleReorderMode}
                                        />
                                    </div>
                                </>
                            }
                            <Droppable droppableId="lesson1" type="lessons">
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                            lessons1.map((lesson, lessonIndex) => 
                                                renderLesson('lesson1', lesson, lessonIndex, lessons1)
                                            )
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            {
                                lessons2.length > 0 && <div className="md:hidden">
                                    <LoadingButton
                                        text="Keep current study plan"
                                        onClick={() => onOk(getStudyPlan(plan, lessons1))}
                                    />
                                </div>
                            }
                        </div>

                        {
                            lessons2.length > 0 && <div className="flex-1">
                                <hr className="md:hidden mb-2" />
                                <div className="flex items-center mb-2">
                                    <div className="">Suggested study plan:</div>
                                    <LoadingButton imgSrc="/icon-refresh.png" text="Generate new suggestion" 
                                        onClick={() => onPreviewStudyPlans(plan, incrementNonse())} />
                                    <LoadingButton imgSrc="/icon-list-plus2.png" text="Make longer" 
                                        onClick={() => incrementNumLessons(1)} />
                                    <LoadingButton imgSrc="/icon-list-minus2.png" text="Make shorter" 
                                        onClick={() => incrementNumLessons(-1)} />
                                    <div className="flex-grow"></div>
                                    <LoadingButton 
                                        imgSrc="/icon-list-reorder3.png" 
                                        text={isReorderMode ? "Done Reordering" : "Reorder"}
                                        bgColor={isReorderMode ? 'white' : 'transparent'}
                                        onClick={toggleReorderMode}
                                    />
                                </div>
                                <Droppable droppableId="lesson2" type="lessons">
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {
                                                lessons2.map((lesson, lessonIndex) => 
                                                    renderLesson('lesson2', lesson, lessonIndex, lessons2)
                                                )
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                                <div className="md:hidden">
                                    <LoadingButton
                                        text="Accept suggested study plan"
                                        onClick={() => onOk(getStudyPlan(plan, lessons2))}
                                    />
                                </div>
                            </div>
                        }
                    </DragDropContext>
                </div>

                {
                    lessons2.length > 0 && <div className="hidden md:flex justify-center gap-2 mt-4">
                        <LoadingButton
                            text="Keep current study plan"
                            onClick={() => onOk(getStudyPlan(plan, lessons1))}
                        />
                        <LoadingButton
                            text="Accept suggested study plan"
                            onClick={() => onOk(getStudyPlan(plan, lessons2))}
                        />
                    </div>
                }
            </div>
        }

    </div>
};

export default EditStudyPlan;
