import React, { useState } from 'react';
import DialogBox from './DialogBox';

const AddNewLesson = ({ isDialogOpen, setIsDialogOpen, onAddLesson }) => {
    const [newLessonGoals, setNewLessonGoals] = useState('');
    const [isAddLessonSaving, setIsAddLessonSaving] = useState(false);

    const handleAddLessonSubmit = async () => {
        if (!newLessonGoals.trim()) {
            handleAddLessonCancel();
            return;
        }
        
        setIsAddLessonSaving(true);
        
        try {
            // Call the callback with the new lesson goals
            await onAddLesson(newLessonGoals);
        } finally {
            setIsAddLessonSaving(false);
            handleAddLessonCancel();
        }
    };

    const handleAddLessonCancel = () => {
        setIsDialogOpen(false);
        setNewLessonGoals('');
    };

    const handleAddLessonKeyDown = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAddLessonSubmit();
        } else if (e.key === 'Escape') {
            e.preventDefault();
            handleAddLessonCancel();
        }
    };

    return (
        <DialogBox
            title="Add a new lesson"
            buttonText="Add"
            isLoading={isAddLessonSaving}
            isDialogOpen={isDialogOpen}
            setIsDialogOpen={setIsDialogOpen}
            onOk={handleAddLessonSubmit}
            onCancel={handleAddLessonCancel}
        >
            <div className="flex flex-col gap-2">
                <div className="text-md text-gray-600">
                    Enter the lesson's goals (eg, list of new concepts or topics to learn):
                </div>
                <textarea
                    className="border border-gray-300 p-2 w-full"
                    value={newLessonGoals}
                    maxLength={120}
                    onChange={(e) => setNewLessonGoals(e.target.value)}
                    onKeyDown={handleAddLessonKeyDown}
                    rows={2}
                    autoFocus
                />
            </div>
        </DialogBox>
    );
};

export default AddNewLesson; 