const apiUrl = process.env.REACT_APP_LF_API_URL || 'http://localhost:5001';

export function stream_get(path, params, timeoutMs = 30000) {
    const urlParams = new URLSearchParams(params);
    const baseUrl = apiUrl;
    
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeoutMs);
    
    return fetch(
        `${baseUrl}/${path}?${urlParams.toString()}`,
        {
            credentials: 'include',
            signal: controller.signal
        }
    ).finally(() => clearTimeout(timeoutId));
}

export function stream_post(path, params, timeoutMs = 30000) {
    const baseUrl = apiUrl;
    
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeoutMs);
    
    return fetch(
        `${baseUrl}/${path}`,
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
            signal: controller.signal
        }
    ).finally(() => clearTimeout(timeoutId));
}
