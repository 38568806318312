const StudyLesson = ({
    lesson, 
    index, 
    isLastLesson, 
    children, 
    onLessonClick,
    lessonBoxStyle = "bg-white",
    lessonTitleStyle = "font-semibold",
    lessonExplanationStyle = "",
    isReorderMode = false,
}) => {
return <div key={index} className="flex items-start mb-4">
    <div className="mr-4 flex flex-col items-center">
        <div className={`w-12 h-12 ${lessonBoxStyle} border border-black flex items-center justify-center rounded-md`} onClick={onLessonClick}>
            {index + 1}
        </div>
        {
            !isLastLesson && <div className="w-12 text-center border-t-black">
                ⇣
            </div>
        }
    </div>
    <div>
        <div className={`${isReorderMode ? 'pr-10' : ''} flex-1`}>
            <div className={lessonTitleStyle}>{lesson.title}</div>
            <div className={lessonExplanationStyle}>{lesson.explanation}</div>
        </div>
        {children}
    </div>
</div>
};

export default StudyLesson;
