import React, { useState } from 'react';
import { useError } from '../contexts/ErrorContext';
import { api_post } from '../api/lfApi';

async function postFeedback(contentId, score, text, setError) {
    const response = await api_post(
        '/feedback/', 
        {
            content_id: contentId,
            score: Math.round(score),
            text: text,
        },
        setError,
    );
    return response !== null;
};

const FeedbackButton = ({ contentIds, data }) => {
    const { setError } = useError();
  
    const [isReported, setIsReported] = useState(false);

    const handleClick = async () => {
        let score = 50;

        if (isReported) {
            setIsReported(false);
            score = 100;
        }
        else {
            setIsReported(true);
            score = 0;
        }

        const feedbackPromises = contentIds.filter(contentId => contentId).map((contentId, index) => {
            const currentScore = index === 0 ? score : Math.max(50, score);
            return postFeedback(contentId, currentScore, JSON.stringify(data), setError);
        });
        await Promise.all(feedbackPromises);
    };

    return <div className="cursor-pointer" onClick={handleClick}>
        <img src={isReported ? "/icon-thumb-down-light-gray.png" : "/icon-thumb-down.png"}
            className="w-8 h-8 p-1 border border-white rounded-lg hover:bg-gray-200"
            alt="Report content" />
    </div>;
};

export default FeedbackButton;
