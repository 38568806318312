import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import StudyLesson from './StudyLesson';
import LoadingButton from './LoadingButton';
import LoadingIndicator from './LoadingIndicator';
import EditLesson from './EditLesson';
import { streamStudyPlan } from '../api/lfApiStudyPlan';

const ReviewLessons = ({ 
    lessons1, 
    lessons2, 
    planProp1, 
    planProp2, 
    plan,
    username,
    setLessons1,
    setLessons2,
    setPlanProp1,
    setPlanProp2,
    children
}) => {
    const [draggingDraggableId, setDraggingDraggableId] = useState(false);
    const [isReorderMode, setIsReorderMode] = useState(false);
    const [selectedLesson, setSelectedLesson] = useState(null); // Format: {list: 'lesson1'|'lesson2', index: number}
    
    // Add edit dialog state
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [editingLesson, setEditingLesson] = useState(null);
    const [editingListId, setEditingListId] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);

    const maxNonse = 5;

    const _incrementNonse = (planProp, setPlanProp, maxNonse) => {
        const n = (planProp.nonse + 1) % maxNonse;
        const newState = { ...planProp, nonse: n };
        setPlanProp(newState);
        return newState;
    };

    const incrementNonse1 = () => {
        return _incrementNonse(planProp1, setPlanProp1, maxNonse);
    };

    const incrementNonse2 = () => {
        return _incrementNonse(planProp2, setPlanProp2, maxNonse);
    };

    const _incrementNumLessons = (planProp, setPlanProp, incr) => {
        const minNumLessons = 3;
        const maxNumLessons = 8;
        
        const currentNumLessons = parseInt(planProp.num_lessons);
        const newNumLessons = currentNumLessons + incr;
        if (newNumLessons < minNumLessons || newNumLessons > maxNumLessons) {
            return planProp;
        }
        const newState = { ...planProp, num_lessons: newNumLessons.toString() };
        setPlanProp(newState);
        return newState;
    };

    const incrementNumLessons1 = (incr) => {
        return _incrementNumLessons(planProp1, setPlanProp1, incr);
    };

    const incrementNumLessons2 = (incr) => {
        return _incrementNumLessons(planProp2, setPlanProp2, incr);
    };

    const fetchSuggestedStudyPlan1 = async (plan, planProp) => {
        await streamStudyPlan(plan, username, planProp, 'default', setLessons1);
    };

    const fetchSuggestedStudyPlan2 = async (plan, planProp) => {
        await streamStudyPlan(plan, username, planProp, 'secondary', setLessons2);
    };

    const handleGenerateNewSuggestion1 = async () => {
        await fetchSuggestedStudyPlan1(plan, incrementNonse1());
    };

    const handleGenerateNewSuggestion2 = async () => {
        await fetchSuggestedStudyPlan2(plan, incrementNonse2());
    };

    const handleMakeLonger1 = async () => {
        await fetchSuggestedStudyPlan1(plan, incrementNumLessons1(1));
    };

    const handleMakeLonger2 = async () => {
        await fetchSuggestedStudyPlan2(plan, incrementNumLessons2(1));
    };

    const handleMakeShorter1 = async () => {
        await fetchSuggestedStudyPlan1(plan, incrementNumLessons1(-1));
    };

    const handleMakeShorter2 = async () => {
        await fetchSuggestedStudyPlan2(plan, incrementNumLessons2(-1));
    };

    // Implement onLessonsChange functionality directly
    const handleLessonsChange1 = (updatedLessons) => {
        setLessons1(updatedLessons);
    };

    const handleLessonsChange2 = (updatedLessons) => {
        setLessons2(updatedLessons);
    };

    const handleLessonSelect = (source, index) => {
        if (!isReorderMode) {
            if (selectedLesson && selectedLesson.list === source && selectedLesson.index === index) {
                // Unselect if clicking the same lesson
                setSelectedLesson(null);
            } else {
                // Select the lesson
                setSelectedLesson({ list: source, index });
            }
        }
    };

    const handleEditLesson = (source, index) => {
        const lesson = source === 'lesson1' ? lessons1[index] : lessons2[index];
        
        // Set up the editing state
        setEditingLesson(lesson);
        setEditingListId(source);
        setEditingIndex(index);
        
        setIsEditDialogOpen(true);
    };
    
    const handleSaveEdit = (updatedLesson) => {
        const setLessons = editingListId === 'lesson1' ? setLessons1 : setLessons2;
        const lessons = editingListId === 'lesson1' ? lessons1 : lessons2;
        
        const updatedLessons = [...lessons];
        updatedLessons[editingIndex] = updatedLesson;
        
        setLessons(updatedLessons);
        handleCloseEditDialog();
    };
    
    const handleCloseEditDialog = () => {
        setIsEditDialogOpen(false);

        setEditingLesson(null);
        setEditingListId('');
        setEditingIndex(null);
    };

    const handleDeleteLesson = (source, index) => {
        if (source === 'lesson1') {
            const updatedLessons = [...lessons1];
            updatedLessons.splice(index, 1);
            handleLessonsChange1(updatedLessons);
        } else {
            const updatedLessons = [...lessons2];
            updatedLessons.splice(index, 1);
            handleLessonsChange2(updatedLessons);
        }
        setSelectedLesson(null);
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination || source.droppableId === destination.droppableId) {
            const lessons = source.droppableId === 'lesson1' ? lessons1 : lessons2;
            const setLessons = source.droppableId === 'lesson1' ? handleLessonsChange1 : handleLessonsChange2;

            const updatedLessons = [...lessons];
            const [removed] = updatedLessons.splice(source.index, 1);
            if (destination) {
                updatedLessons.splice(destination.index, 0, removed);
            }
            setLessons(updatedLessons);
        } else {
            const sourceLessons = source.droppableId === 'lesson1' ? lessons1 : lessons2;
            const destinationLessons = destination.droppableId === 'lesson1' ? lessons1 : lessons2;
            const setSourceLessons = source.droppableId === 'lesson1' ? handleLessonsChange1 : handleLessonsChange2;
            const setDestinationLessons = destination.droppableId === 'lesson1' ? handleLessonsChange1 : handleLessonsChange2;

            const updatedSourceLessons = [...sourceLessons];
            const updatedDestinationLessons = [...destinationLessons];
            
            const [removed] = updatedSourceLessons.splice(source.index, 1);
            updatedDestinationLessons.splice(destination.index, 0, removed);

            setSourceLessons(updatedSourceLessons);
            setDestinationLessons(updatedDestinationLessons);
        }
    };

    const onDragUpdate = (update) => {
        setDraggingDraggableId(!update.destination ? update.draggableId : '');
    };

    const renderLesson = (lesson, index, source) => {
        const lessons = source === 'lesson1' ? lessons1 : lessons2;
        const isSelected = selectedLesson && selectedLesson.list === source && selectedLesson.index === index;
        const lessonBoxStyle = isSelected ? 'bg-blue-500 text-white border-blue-500' : 'bg-white';
        const draggableId = `${source}:${index}`;
        const isDragging = draggableId === draggingDraggableId;

        return (
            <div key={`${source}-container-${index}`} 
                 className={`relative border border-transparent`}>
                <Draggable 
                    key={draggableId} 
                    draggableId={draggableId} 
                    index={index}
                    isDragDisabled={!isReorderMode}
                >
                    {(provided) => (
                        <div 
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={`${isDragging ? 'opacity-50' : 'opacity-100'}`}
                        >
                            <div 
                                className={!isReorderMode ? "cursor-pointer" : ""}
                                onClick={() => handleLessonSelect(source, index)}>
                                <StudyLesson 
                                    lesson={lesson} 
                                    index={index} 
                                    isLastLesson={index === lessons.length - 1} 
                                    lessonBoxStyle={lessonBoxStyle}
                                    isReorderMode={isReorderMode}
                                />
                            </div>
                            
                            {isSelected && (
                                <div className="absolute top-2 right-2 flex space-x-1">
                                    <LoadingButton 
                                        imgSrc="/icon-edit.png" 
                                        text="Edit"
                                        bgColor="white"
                                        onClick={() => handleEditLesson(source, index)}
                                    />
                                    <LoadingButton 
                                        imgSrc="/icon-trash.png" 
                                        text="Delete"
                                        bgColor="white"
                                        onClick={() => handleDeleteLesson(source, index)}
                                    />
                                </div>
                            )}
                            
                            {isReorderMode && (
                                <div 
                                    {...provided.dragHandleProps}
                                    className="absolute top-0 bottom-0 right-2 flex items-center justify-center h-full cursor-grab"
                                >
                                    <span className="text-2xl text-gray-500 px-2">≡</span>
                                </div>
                            )}
                        </div>
                    )}
                </Draggable>
            </div>
        );
    };

    return (
        <>
            <EditLesson
                lesson={editingLesson}
                isOpen={isEditDialogOpen}
                onClose={handleCloseEditDialog}
                onSave={handleSaveEdit}
            />
            
            <div className="flex flex-col md:flex-row gap-4 text-left mt-4">
                <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
                    <div className="flex-1 relative md:pb-4">
                        {
                            lessons2.length > 0 && <>
                                <hr className="md:hidden" />
                                <div className="flex items-center my-2">
                                    <div className="mr-1">Suggestion 1:</div>
                                    <LoadingButton imgSrc="/icon-refresh.png" text="Generate new suggestion" 
                                        onClick={handleGenerateNewSuggestion1} />
                                    <LoadingButton imgSrc="/icon-list-plus2.png" text="Make longer" 
                                        onClick={handleMakeLonger1} />
                                    <LoadingButton imgSrc="/icon-list-minus2.png" text="Make shorter" 
                                        onClick={handleMakeShorter1} />
                                    <div className="flex-grow"></div>
                                    <LoadingButton 
                                        imgSrc="/icon-list-reorder3.png" 
                                        text={isReorderMode ? "Done Reordering" : "Reorder Lessons"}
                                        bgColor={isReorderMode ? 'white' : 'transparent'}
                                        onClick={() => {
                                            setIsReorderMode(!isReorderMode);
                                            setSelectedLesson(null);
                                        }}
                                    />
                                </div>
                            </>
                        }
                        <Droppable droppableId="lesson1">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {
                                        lessons1.length === 0 && <LoadingIndicator />
                                    }
                                    {
                                        lessons1.map((lesson, index) => renderLesson(lesson, index, 'lesson1'))
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        {React.Children.toArray(children)[0]}
                    </div>

                    {
                        lessons2.length === 0 && <LoadingIndicator />
                    }
                    {
                        lessons2.length > 0 && <div className="flex-1 relative pb-4">
                                <hr className="md:hidden" />
                                <div className="flex items-center my-2">
                                    <div className="mr-1">Suggestion 2:</div>
                                    <LoadingButton imgSrc="/icon-refresh.png" text="Generate new suggestion" 
                                        onClick={handleGenerateNewSuggestion2} />
                                    <LoadingButton imgSrc="/icon-list-plus2.png" text="Make longer" 
                                        onClick={handleMakeLonger2} />
                                    <LoadingButton imgSrc="/icon-list-minus2.png" text="Make shorter" 
                                        onClick={handleMakeShorter2} />
                                    <div className="flex-grow"></div>
                                    <LoadingButton 
                                        imgSrc="/icon-list-reorder3.png" 
                                        text={isReorderMode ? "Done Reordering" : "Reorder Lessons"}
                                        bgColor={isReorderMode ? 'white' : 'transparent'}
                                        onClick={() => {
                                            setIsReorderMode(!isReorderMode);
                                            setSelectedLesson(null);
                                        }}
                                    />
                                </div>
                                <Droppable droppableId="lesson2">
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                            lessons2.map((lesson, index) => renderLesson(lesson, index, 'lesson2'))
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                                {React.Children.toArray(children)[1]}
                        </div>
                    }
                </DragDropContext>
            </div>
        </>
    );
};

export default ReviewLessons; 